<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Payment Processing</h3>
    </div>
    <div class="card-section">
        <div class="card-panel">
            <form [formGroup]="paymentProcForm" (ngSubmit)="cMProcessor_Update()">
                <div class="field-form-panel">
                    <!--<div class="flex-form">-->
                    <div class="row margin-top10">
                        <div class="form-group col-sm-3">
                            <label>Vendor</label>
                            <kendo-combobox class="form-control" [data]="vendors"
                                            [valuePrimitive]="true"
                                            textField="vendorName"
                                            [kendoDropDownFilter]="filterSettings"
                                            [filterable]="true"
                                            formControlName="vendorID"
                                            valueField="vendorID">
                            </kendo-combobox>
                        </div>
                        <div class="form-group col-sm-3">
                            <label>Environment</label>
                            <kendo-combobox class="form-control" [data]="env"
                                            [valuePrimitive]="true"
                                            textField="envName"
                                            [kendoDropDownFilter]="filterSettings"
                                            [filterable]="true"
                                            formControlName="activeEnvironment"
                                            valueField="envID">
                            </kendo-combobox>
                        </div>
                        <div class="form-group col-sm-2">
                            <div class="custom-control custom-switch payment-process-check">
                                <input type="checkbox" class="custom-control-input" id="isACHActive" formControlName="isACHActive" maxlength="50">
                                <label class="custom-control-label" for="isACHActive">ACH</label>
                            </div>
                        </div>
                        <div class="form-group col-sm-2">
                            <div class="custom-control custom-switch payment-process-check">
                                <input type="checkbox" id="isCCActive" class="custom-control-input" formControlName="isCCActive" maxlength="50">
                                <label class="custom-control-label" for="isCCActive">Credit Card</label>
                            </div>
                        </div>
                    </div>
                    <div class="row margin-top10">
                        <div class="form-group col-sm-4">
                            <label>Production API URL</label>
                            <input class="form-control" type="text" formControlName="prodAPIURL" maxlength="200" />
                        </div>
                        <div class="form-group col-sm-4">
                            <label>Production Credential 1</label>
                            <input class="form-control" type="text" formControlName="prodCred1" maxlength="400" />
                        </div>
                        <div class="form-group col-sm-4">
                            <label>Production Credential 2</label>
                            <input class="form-control" type="text" formControlName="prodCred2" maxlength="400" />
                        </div>
                    </div>
                    <div class="row margin-top10">
                        <div class="form-group col-sm-4">
                            <label>Test API URL</label>
                            <input class="form-control" type="text" formControlName="testAPIURL" maxlength="200" />
                        </div>
                        <div class="form-group col-sm-4">
                            <label>Test Credential 1</label>
                            <input class="form-control" type="text" formControlName="testCred1" maxlength="400" />
                        </div>
                        <div class="form-group col-sm-4">
                            <label>Test Credential 2</label>
                            <input class="form-control" type="text" formControlName="testCred2" maxlength="400" />
                        </div>
                    </div>
                    <div class="row margin-top10">
                        <div class="form-group col-sm-4">
                            <label>Sandbox API URL</label>
                            <input class="form-control" type="text" formControlName="sandboxAPIURL" maxlength="50" />
                        </div>
                        <div class="form-group col-sm-4">
                            <label>Sandbox Credential 1</label>
                            <input class="form-control" type="text" formControlName="sandboxCred1" maxlength="400" />
                        </div>
                        <div class="form-group col-sm-4">
                            <label>Sandbox Credential 2</label>
                            <input class="form-control" type="text" formControlName="sandboxCred2" maxlength="400" />
                        </div>
                    </div>
                    <div class="row margin-top10">
                        <div class="form-group col-sm-2">
                            <label>Custom 1</label>
                            <input class="form-control" type="text" formControlName="custom1" maxlength="400" />
                        </div>
                        <div class="form-group col-sm-2">
                            <label>Custom 2</label>
                            <input class="form-control" type="text" formControlName="custom2" maxlength="400" />
                        </div>
                        <div class="form-group col-sm-2">
                            <label>Custom 3</label>
                            <input class="form-control" type="text" formControlName="custom3" maxlength="400" />
                        </div>
                        <div class="form-group col-sm-2">
                            <label>Custom 4</label>
                            <input class="form-control" type="text" formControlName="custom4" maxlength="400" />
                        </div>
                        <div class="form-group col-sm-2">
                            <label>Custom 5</label>
                            <input class="form-control" type="text" formControlName="custom5" maxlength="400" />
                        </div>
                    </div>
                    <div class="row margin-top10">
                        <div class="form-group col-sm-2">
                            <div class="custom-control custom-switch payment-process-check">
                                <input type="checkbox" class="custom-control-input" id="isSendDiagEmail" formControlName="isSendDiagEmail" maxlength="50">
                                <label class="custom-control-label" for="isSendDiagEmail">Send Exception Email</label>
                            </div>
                        </div>
                        <div class="form-group col-sm-4">
                            <label>Email</label>
                            <input class="form-control" type="text" formControlName="diagEmails" maxlength="50" />
                        </div>
                    </div>
                    <div class="row margin-top10">
                        <div class="form-group col-sm-12">
                            <button type="submit" class="primary-btn">Save</button>
                        </div>
                    </div>
                    <!--</div>-->
                </div>
            </form>
            <div class="button-group">
                <div class="form-group">
                    <div class="button-group">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


