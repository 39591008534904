import { TeamCodes } from "./clpuser.model";
import { SimpleResponse } from "./generic-response.model";

export interface CMClub {
    clubId: number;
    teamCode: number;
    clpcompanyID: number;
    clubName: string;
    eIN: string;
    region: string;
    territory: string;
    city: string;
    address: string;
    country: string;
    state: string;
    zip: string;
    userId: number;
    dtCreated: string;
    dtModified: string;
    createdBy: number;
    ModifiedBy: number;
    DLId: string;
    EmergencyNameId: string;
    EmergencyPhoneId: string;
    DOBId: string;
}

export interface CMProcessor {
    cmProcessorID: number;
    clubID: number;
    vendorID: number;
    prodAPIURL: string;
    prodCred1: string;
    prodCred2: string;
    testAPIURL: string;
    testCred1: string;
    testCred2: string;
    sandboxAPIURL: string;
    sandboxCred1: string;
    sandboxCred2: string;
    custom1: string;
    custom2: string;
    custom3: string;
    custom4: string;
    custom5: string;
    isCCActive: boolean;
    isACHActive: boolean;
    activeEnvironment: number;
    isSendDiagEmail: boolean;
    diagEmails: string;
}

export interface CMProcessorVendor {
    vendorID: number;
    vendorName: string;
}

export interface CMEnvironment {
    envID: number;
    envName: string;
}

export interface CMClubSiteConfig {
    iD: number;
    clubID: number;
    teamCode: number;
    clpCompanyID: number;
    lnkCustom1URL: string;
    lnkCustom2URL: string;
    lnkCustom3URL: string;
    lnkCustom4URL: string;
    lnkCustom5URL: string;
    mktCustomText1: string;
    mktCustomText2: string;
    mktCustomText3: string;
    mktCustomText4: string;
    mktCustomText5: string;
    mktMultitext1: string;
    mktMultitext2: string;
    mktMultitext3: string;
    mktMultitext4: string;
    mktMultitext5: string;
    engDashCustomMMT1D1: string;
    engDashCustomMMT1D2: string;
    engDashCustomMMT1D3: string;
    engDashCustomMMT1D4: string;
    engDashCustomMMT1D5: string;
    engDashCustomMMT1D6: string;
    engDashCustomMMT1D7: string;
    engDashCustomMMT1D8: string;
    engDashCustomMMT1D9: string;
    engDashCustomMMT1D10: string;
    dtCreated: string;
    dtModified: string;
    createdBy: number;
    modifiedBy: number;
}

export interface CMProcessorResponse extends SimpleResponse {
    processor: CMProcessor;
}

export interface CMProcessorVendorListResponse extends SimpleResponse {
    vendors: CMProcessorVendor[];
}

export interface CMClubResponse extends SimpleResponse {
    clubConfig: CMClub;
}

export interface CMClubSiteConfigListResponse extends SimpleResponse {
    clubSiteConfigs: CMClubSiteConfig[];
}

export interface AutomationProcessDD {
    campaignTemplateID: number;
    cLPUserID: number;
    cLPCompanyID: number;
    campaignTemplateName: string;
    templateName: string;
}

export interface CMSiteListResponse extends SimpleResponse {
    sites: CMSite[];
}

export interface CMSite {
    siteID: number;
    clubID: number;
    class1Code: number;
    clpcompanyID: number;
    siteName: string;
    teamCode: number;
    address: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    taxRate: number;
    scheduleSiteVisitURL: string;
    scheduleOpenHouseURL: string;
    vesselsURL: string;
    kioskURL: string;
    lnkCustom1URL: string;
    lnkCustom2URL: string;
    lnkCustom3URL: string;
    lnkCustom4URL: string;
    lnkCustom5URL: string;
    mktCustomText1: string;
    mktCustomText2: string;
    mktCustomText3: string;
    mktCustomText4: string;
    mktCustomText5: string;
    mktMultiText1: string;
    mktMultiText2: string;
    mktMultiText3: string;
    mktMultiText4: string;
    mktMultiText5: string;
    engDashCustomMMT1D1: string;
    engDashCustomMMT1D2: string;
    engDashCustomMMT1D3: string;
    engDashCustomMMT1D4: string;
    engDashCustomMMT1D5: string;
    engDashCustomMMT1D6: string;
    engDashCustomMMT1D7: string;
    engDashCustomMMT1D8: string;
    engDashCustomMMT1D9: string;
    engDashCustomMMT1D10: string;
    dtCreated: string;
    dtModified: string;
    newLead: number,
    newMember: number,
    contractSent: number,
    contractExpiration: number,
    contactUpdate: number,
    referralRequest: number,
    teamCodeDisplay: string,
    class1Display: string,
    phLocation: string,
    contractOpen: number,
    waitingCountersign: number
}

export interface CMSiteEmployeesListResponse extends SimpleResponse {
    listSiteEmployees: CMSiteEmployees[];
}

export interface CMSiteEmployees {
    iD: number;
    siteID: number;
    clubID: number;
    class1Code: number;
    clpcompanyId: number;
    roleName: string;
    userID: number;
    dtCreated: string;
    dtModified: string;
    userName: string;
    phoneNumber: string;
    email: string;
    siteName: string;
    eSigFullImage: string;
    eSigLength: string;
}

export interface CMSiteEmployeesAccept {
    cmEmployeeID: number;
    cLPUserId: number;
    contactId: number;
    cmContractID: number;
    eSigFullImage: string;
    eSigLength: number;
}

export interface CMSiteEmployeesUdtRequest {
    siteID: number;
    clubID: number;
    class1Code: number;
    clpcompanyId: number;
    roleName: string;
    userID: number;
}

export interface FilterTeam_Load_GeneralResponse extends SimpleResponse {
    filterMappedTeams: TeamCodes[];
    filterNotMappedTeams: TeamCodes[];
    team: TeamCodes;
}

export interface SiteDDListResponse extends SimpleResponse {
    sites: CMSite[],
    clubId: number,
    agentName: string
}

export interface CMSiteExistsResponse extends SimpleResponse {
    clubID: number;
    clubName: string;
}
