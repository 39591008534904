<div class="main-wrapper-section">
    <div class="common-header">
        <h3>General</h3>
    </div>
    <div class="card-section">
        <div class="card-panel">
            <p class="heading-text" style="color:#2a3644; font-weight:600;">SalesOptima Site Configuration</p>
            <div class="flex-form" style="align-items:flex-end">
                <div class="form-group flex-none min-width300">
                    <label>Site (Class 1)</label>

                    <h5>{{curSite?.class1Display}}</h5>
                </div>
                <div class="form-group flex-none min-width300">
                    <label>Club</label>
                    <h5>{{curTeam?.display}}</h5>
                </div>
            </div>
            <hr />
            <p class="heading-text">The following fields are used to customize each club. <a href="javascript:void();">Full Placeholder List</a></p>
            <div class="flex-form">
                <div class="form-group flex-none min-width300">
                    <label>Site (PH_Location)</label>
                    <input class="form-control" [(ngModel)]="phLocation" maxlength="50" />
                </div>
                <div class="form-group flex-none min-width300">
                    <label>Tax Rate %</label>
                    <input class="form-control" type="text" [(ngModel)]="taxRate" maxlength="50" style="text-align: right;" appPercentageDirective />
                </div>
            </div>
            <div class="flex-form">
                <div class="form-group flex-none min-width300">
                    <label>Address</label>
                    <input class="form-control" [(ngModel)]="phAddress" maxlength="50" />
                </div>
                <div class="form-group flex-none min-width300">
                    <label>City</label>
                    <input class="form-control" [(ngModel)]="phCity" maxlength="50" />
                </div>
                <div class="form-group">
                    <label>State</label>
                    <kendo-combobox [data]="stateList"
                                    class="form-control min-width300"
                                    [valuePrimitive]="true"
                                    [kendoDropDownFilter]="filterSettings"
                                    [filterable]="true"
                                    [(ngModel)]="stateCode"
                                    textField="text"
                                    valueField="value">
                    </kendo-combobox>
                </div>
            </div>
            <div class="flex-form">
                <div class="form-group">
                    <label class="fldtitle">Zip</label>
                    <input type="text" name="txtZip" [(ngModel)]="phZip" maxlength="6" autocomplete="off" value="247001" id="ContentPlaceHolder1_txtZip" class="form-control">
                </div>
                <div class="form-group">
                    <label>Country</label>
                    <kendo-combobox [data]="countryDD"
                                    class="form-control min-width300"
                                    [valuePrimitive]="true"
                                    [kendoDropDownFilter]="filterSettings"
                                    [filterable]="true"
                                    textField="text"
                                    [(ngModel)]="countryCode"
                                    valueField="value"
                                    (valueChange)="onChangeCountry($event)">
                    </kendo-combobox>
                </div>
            </div>
            <div class="button-group">
                <div class="form-group">
                    <button class="primary-btn" (click)="updateSite()" [disabled]="!getValid()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
