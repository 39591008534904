<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Employees</h3>
    </div>
    <div class="table-section">
        <div class="card-section">
            <div class="card-panel">
                <div class="table-panel">
                    <div class="table-body">
                        <form [formGroup]="addEmployeeForm" (ngSubmit)="siteEmployeesBulkInsert()">
                            <table class="table table-border" style="width:50%;">
                                <thead>
                                    <tr>
                                        <th>Employee</th>
                                        <th style="width:50%;">Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="table-row">
                                                <div>
                                                    <kendo-combobox formControlName="user" class="form-control min-width300" [data]="clpUsersDD" [valuePrimitive]="true"
                                                                    textField="text" [kendoDropDownFilter]="filterSettings" [filterable]="true" valueField="value">
                                                    </kendo-combobox>
                                                    <div *ngIf="addEmployeeForm.controls.user.errors && (addEmployeeForm.controls.user.touched || addEmployeeForm.controls.user.dirty)">
                                                        <div class="login-error" *ngIf="addEmployeeForm.controls.user.errors.required">User is required. </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <kendo-dropdownlist #dropdownlist
                                                                [data]="allRolesList"
                                                                [filterable]="true"
                                                                formControlName="role"
                                                                (close)="onClose($event)"
                                                                (filterChange)="handleFilter($event)">
                                                <ng-template kendoDropDownListNoDataTemplate>
                                                    <div>
                                                        No data found.
                                                        <button class="add-new" *ngIf="filter" kendoButton (click)="addNew()">
                                                            Add new
                                                        </button>
                                                    </div>
                                                </ng-template>
                                            </kendo-dropdownlist>
                                            <div *ngIf="addEmployeeForm.controls.role.errors && (addEmployeeForm.controls.role.touched || addEmployeeForm.controls.role.dirty)">
                                                <div class="login-error" *ngIf="addEmployeeForm.controls.role.errors.required">Role is required. </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="button-group">
                            <div class="display-flex">
                                <div class="form-group">
                                    <button type="submit" class="primary-btn">Add Employee<i *ngIf="submitted" class="fas fa-spinner fa-spin"></i></button>
                                </div>
                                <div class="form-group" style="flex:0">
                                    <select class="form-control min-width100" style="width:auto; margin-left:10px;" *ngIf="isShowOp" (change)="clubSiteLevel($event.target.value)">
                                        <option [value]=-2>Select Action</option>
                                        <option [value]=0>--Cancel Clone--</option>
                                        <option [value]=-1>--All Sites--</option>
                                        <option *ngFor="let item of sites; let i=index" [value]="item.siteID">{{item.siteName}}</option>
                                    </select>
                                    <button class="btn primary-btn margin-left15" *ngIf="!isShowOp && sites?.length > 0 && siteEmployeesList?.length > 0 " (click)="showCloneDD()">Clone</button>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="table-panel">
                    <div class="table-body employees">
                        <table mat-table [dataSource]="dataSource" class="" matSort>
                            <ng-container matColumnDef="userName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="display-flex" style="align-items:center;">
                                        <span>{{element.userName}}</span>

                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="phoneNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
                                <td mat-cell *matCellDef="let element">{{element.phoneNumber}}</td>
                            </ng-container>
                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef>Email</th>
                                <td mat-cell *matCellDef="let element">{{element.email}}</td>
                            </ng-container>
                            <ng-container matColumnDef="roleName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
                                <td mat-cell *matCellDef="let element">{{element.roleName}}</td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>Action</th>
                                <td mat-cell *matCellDef="let element">
                                    <button class="btn-primary" (click)="onEdit(element)"><span></span></button>
                                    <button class="btn-primary" data-toggle="modal" data-target="#deleteRoleModal" (click)="openDltConfirm(element)"><span></span></button>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)"
                                [ngClass]="{'selected-row': selectedRowIndex == row.sn}">
                            </tr>

                        </table>
                        <mat-paginator *ngIf="siteEmployeesList?.length > 0" [length]="siteEmployeesList?.length" [pageSizeOptions]="[5, 10, 15, 50]"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="deleteRoleModal" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <h6 class="modal-title">Are you sure you want to delete this Employee</h6>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-success" (click)="onDeleteEmp()">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
