import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BCMMenuResponse, MenuResponse } from '../../models/menu.model';
import { delayedRetryHttp } from './delayed-retry';
import { UtilityService } from './utility.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    private baseUrl: string;
    private api: string = "api/BCMMenu";
    baseUrlAI: string;
    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async getMenu(encryptedUser: string, userId: number, clpCompanyId: number): Promise<BCMMenuResponse | void> {
        const a = await this.httpClient
            .get<BCMMenuResponse>(`${this.baseUrl}/Menu_Get/${userId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "userId - " + userId, encryptedUser, "MenuService", "getMenu"); });
        return a;
    }

    async getMenuAI(encryptedUser: string, userId: number, clpCompanyId: number): Promise<MenuResponse | void> {
        const a = await this.httpClient
            .get<MenuResponse>(`${this.baseUrl}/Menu_GetAI/${userId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "userId - " + userId, encryptedUser, "MenuService", "getMenu"); });
        return a;
    }

}
