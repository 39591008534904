import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BCMCLPUser, CLPUser, UserResponse, CMContact } from '../../models/clpuser.model';
import { SimpleResponse } from '../../models/generic-response.model';
import { UtilityService } from './utility.service';

@Injectable({
    providedIn: 'root'
})
export class LocalService {
    private baseUrl: string;
    private api: string = "api/Authentication";
    menu = new BehaviorSubject<boolean>(false);
    route = new BehaviorSubject<string>("cm-dashboard");
    cmManager = new BehaviorSubject<boolean>(true);
    contract = new BehaviorSubject<boolean>(false);
    clientContract = new BehaviorSubject<boolean>(false);
    updateTeam = new BehaviorSubject<boolean>(false);
    isCmManager = this.cmManager.asObservable();
    isShowMenu = this.menu.asObservable();
    isContract = this.contract.asObservable();
    isClientContract = this.clientContract.asObservable();

    routeName = this.route.asObservable();
    baseUrlAI: string;

    constructor(
        private httpClient: HttpClient,
        private _utilityService: UtilityService,
        @Inject('BASE_URL') _baseUrl: string,
    ) {
        this.baseUrl = _baseUrl + this.api;
    }

    updateTeamDD(value: boolean) {
        this.updateTeam.next(value);
    }

    setIsCmManager(value: boolean) {
        this.cmManager.next(value);
    }


    setShowMenu(value: boolean) {
        this.menu.next(value);
    }

    setIsContract(value: boolean) {
        this.contract.next(value);
    }

    setIsClientContract(value: boolean) {
        this.clientContract.next(value);
    }

    setRouteName(value: string) {
        this.route.next(value);
    }

    async authenticateUser(encryptedUser: string, isAI: boolean): Promise<BCMCLPUser | void> {
        const a = await this.httpClient.get<BCMCLPUser>(`${this.baseUrl}/Authenticate?isAI=${isAI}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).toPromise().catch(err => { this._utilityService.handleErrors(err, null, encryptedUser, encryptedUser, "LocalService", "authenticateUser") });
        return a;
    }

    async userTokenSignout(encryptedUser: string, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/UserToken_Signout?isAI=${isAI}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).toPromise().catch(err => { this._utilityService.handleErrors(err, null, encryptedUser, encryptedUser, "LocalService", "userTokenSignout") });
        return a;
    }

    async authenticate(encryptedUser: string, clpuserId: number, contactId: number, isAI: boolean): Promise<UserResponse | void> {
        const a = await this.httpClient.get<UserResponse>(`${this.baseUrl}/AuthenticateUser?contactId=${contactId}&clpuserId=${clpuserId}&isAI=${isAI}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).toPromise().catch(err => { this._utilityService.handleErrors(err, null, encryptedUser, encryptedUser, "LocalService", "userTokenSignout") });
        return a;
    }

    async validateContact(contactID: number, email: string, mobile: string, isAI: boolean = false): Promise<SimpleResponse | void> {

        let contact: CMContact = {
            contactID: contactID,
            email: email,
            mobile: mobile
        }
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ValidateContact?isAI=${isAI}`, contact, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic'
                })
            }).toPromise().catch(err => { this._utilityService.handleErrors(err, "LocalService", "validateContact") });
        return a;
    }
}
