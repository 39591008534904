import { MatTableDataSource } from '@angular/material/table';
import { LineStyle } from '@progress/kendo-angular-charts';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { gearIcon, questionCircleIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { BCMCLPUser, CLPUser, TeamCodes, UserResponse } from '../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../models/role-container.model';
import { UtilityService } from '../../services/shared/utility.service';
import { LocalService } from '../../services/shared/local.service';
import { GlobalService } from '../../services/shared/global.service';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CmDashboardService } from '../../services/cm-dashboard.service';
import { ClubContractsTotalFee, CMContractsBySite, CMContractsByUser, CMDashBoardResponse, ClubContractsStatus } from '../../models/cm-dashboard.modal';
import { CMClubService } from '../../services/cm-club.service';
import { TeamOfficeService } from '../../services/team-office.service';
import { Subscription } from 'rxjs';
import { CMClub, CMClubResponse, CMSite, CMSiteListResponse } from '../../models/cm-club-service.model';

@Component({
    selector: 'app-cm-dashboard',
    templateUrl: './cm-dashboard.component.html',
    styleUrl: './cm-dashboard.component.css'
})
export class CmDashboardComponent {
    public value = 82;
    public animation = true;
    public cashFlowData = [
        {
            period: 'Beginning\\nBalance',
            amount: 15000,
        },
        {
            period: 'Jan',
            amount: 17000,
        },
        {
            period: 'Feb',
            amount: 14000,
        },
        {
            period: 'Mar',
            amount: 12000,
        }
    ];
    public style: LineStyle = "smooth";
    public styleArea: LineStyle = "smooth";
    public categories: string[] = ["Jan", "Feb", "Mar", "Apr", "May"];
    public originalData: any[] = [
        { value: 10 },
        { value: 20 },
        { value: 30 },
        { value: 25 },
        { value: 40 }
    ];
    dataSource = new MatTableDataSource([
        { TotalAmount: '$5000', ContractCount: '10', Avg: '$500' },
    ]);
    dataSourceSite:MatTableDataSource<CMContractsBySite>;
    dataSourceAgent: MatTableDataSource<CMContractsByUser>;
    dataSourceClubFee: MatTableDataSource<ClubContractsTotalFee>;
    dataSourceStatus: MatTableDataSource<ClubContractsStatus>;

    displayedColumns: string[] = ['totalContractFeePaid', 'cMContractCounts', 'avg'];
    displayedColumnsSite: string[] = ['siteName', 'contractCounts'];
    displayedColumnsAgent: string[] = ['userName', 'totalContracts'];
    displayedColumnsStatus: string[] = ['status', 'count'];
    @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;
    public gear: SVGIcon = gearIcon;
    public question: SVGIcon = questionCircleIcon;
    isExpanded = false;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    user: BCMCLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    isAI: boolean = false;
    selectedCtDtFilter: number=1;
    customDateStr: string = '';
    currentRange = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null),
    });
    selectedCtDtFilterName: string;
    selectedCustomdtStart: string;
    selectedCustomdtEnd: string;
    customFilter: boolean = false;
    cmDashBoardResponse: CMDashBoardResponse;
    teamSub: Subscription
    routeNameSUb: Subscription
    updateTeamSub: Subscription
    curSiteSub: Subscription
    curTeam: TeamCodes = <TeamCodes>{};
    sites: CMSite[];
    curSite: CMSite = <CMSite>{};
    clpCompanyId: number = 0;
    clubConfig: CMClub;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _localService: LocalService,
        private _globalService: GlobalService,
        private titleService: Title,
        private _utilityService: UtilityService,
        private datePipe: DatePipe,
        private cmDashboardService: CmDashboardService,
        private clubService: CMClubService,
        private _teamOfficeService: TeamOfficeService
    ) {
        this.titleService.setTitle('Club Manager');
    }

    ngOnInit() {
        this._localService.setShowMenu(true)
        this._localService.setIsCmManager(true);
        this._localService.setRouteName("cm-dashboard")
        this._localService.updateTeamDD(true)

        this._route.queryParams.subscribe(params => {
            if (params?.isAI) {
                this.isAI = params?.isAI && params?.isAI == 'true' ? true : false
                localStorage.setItem("isAI", params?.isAI)
            }
            else {
                this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
            }
            this.encryptedUser = params?.r;
            if (!this.encryptedUser?.includes(":")) {
                this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
            }
            if (params?.r) {
                this.authenticateR().then(() => {
                    if (this.user) {
                        localStorage.setItem("token", this.encryptedUser)
                        this._router.navigate(['/cm-dashboard']);
                    }
                    else {
                        this._router.navigate(['/unauthorized']);
                    }
                });
            }
            else {
                this.encryptedUser = localStorage.getItem("token")
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.clpCompanyId = this.user.cLPCompanyID;
                    }
                    else {
                        this._router.navigate(['/unauthorized']);
                    }
                });
            }
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user.cLPCompanyID;
                    this.loadData();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadData() {
        this.teamSub = this._teamOfficeService.curTeam.subscribe(async (data: any) => {
            if (data != 0 && data != null) {
                this.curTeam = data
                if (this.curTeam?.teamCode > 0) {

                    await this.CMClub_Get();
                    //await this.siteSearch();
                }
            }
            else {
                this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
                if (this.curTeam?.teamCode > 0) {
                    //this.selectedSiteID = 0
                    //this.selectedUserID = 0
                    await this.CMClub_Get();
                    //await this.siteSearch();
                }
            }
        })
        this.curSiteSub = this.clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = await value
            if (this.curSite == null) {
                this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')

            }

        })
    }

    async CMClub_Get() {
        if (this.curTeam?.teamCode > 0) {
           
            await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, this.curTeam?.teamCode, this.isAI).
                then(async (result: CMClubResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.clubConfig = response?.clubConfig;
                        if (this.clubConfig.clubId >0)
                            this.CMDashBoard_byClubGet(this.clubConfig.clubId, 1);
                    }                    
                })
                .catch((err: HttpErrorResponse) => {                   
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    //async siteSearch() {
    //    if (this.clubConfig?.clubId > 0) {
    //        await this.clubService.siteSearch(this.encryptedUser, 0, this.clubConfig?.clubId, 0, 0, this.isAI).
    //            then(async (result: CMSiteListResponse) => {
    //                if (result) {
    //                    let response = UtilityService.clone(result);
    //                    this.sites = response?.sites;                        
    //                }
    //            })
    //            .catch((err: HttpErrorResponse) => {
    //                this._globalService.error("site-fields.clubConfigGet", err.message, null,
    //                );
    //                this._utilityService.handleErrorResponse(err);
    //            });
    //    }
    //}

    async CMDashBoard_byClubGet(clubId:number,timeFrame:number) {
        await this.cmDashboardService.CMDashBoard_byClubGet(this.encryptedUser, clubId, timeFrame,0,this.selectedCustomdtStart,this.selectedCustomdtEnd, this.isAI)
            .then(async (result: CMDashBoardResponse) => {
                if (result) {
                    this.cmDashBoardResponse = UtilityService.clone(result);
                    if (this.cmDashBoardResponse) {
                        this.cmDashBoardResponse.clubContractsTotalFee.forEach((item) => {
                            if (item.totalContractFeePaid > 0) {
                                let _avg = (+item.totalContractFeePaid / +item.cMContractCounts)
                                item.avg = +_avg.toFixed(2);
                            }
                            else
                                item.avg = 0;
                        });
                        this.bindSite();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("CmDashboardComponent.CMDashBoard_byClubGet", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    filterDashboard($event) {
        console.log('filterDashboard', $event);
        this.selectedCtDtFilter = $event;
        if ($event == 3)
            this.customFilter = true;
        else
            this.customFilter = false;
        if (!this.customFilter) {
            this.CMDashBoard_byClubGet(this.clubConfig.clubId, this.selectedCtDtFilter);
        }
    }
    dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
        var startDt = dateRangeStart.value;
        var endDt = dateRangeEnd.value;
        if (startDt) {
            let customdtStart = this.datePipe.transform(startDt, 'MMddyyyy');
            this.selectedCustomdtStart = customdtStart;

        }
        if (endDt) {
            let customdtEnd = this.datePipe.transform(endDt, 'MMddyyyy');
            this.selectedCustomdtEnd = customdtEnd;
            this.selectedCtDtFilter = 3;
            this.selectedCtDtFilterName = "Custom"
            if (this.selectedCustomdtStart && this.selectedCustomdtEnd != null) {
                this.CMDashBoard_byClubGet(this.clubConfig.clubId, this.selectedCtDtFilter);
            }
        }
    }

    revertCustomFinalizeddtFilter() {
        this.customFilter = false;
        this.selectedCtDtFilter = 1;
        this.selectedCtDtFilterName = "This Month";
        this.CMDashBoard_byClubGet(this.clubConfig.clubId, this.selectedCtDtFilter);
    }

    bindSite() {
        this.dataSourceSite = new MatTableDataSource(this.cmDashBoardResponse?.cMContractsBySite);
        this.dataSourceAgent = new MatTableDataSource(this.cmDashBoardResponse?.cMContractsByUser);
        this.dataSourceClubFee = new MatTableDataSource(this.cmDashBoardResponse?.clubContractsTotalFee);
        this.dataSourceStatus = new MatTableDataSource(this.cmDashBoardResponse?.clubContractsStatus);
    }
}
