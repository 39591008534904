import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DropDownFilterSettings, DropDownListComponent, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs';
import { ConfigDetails } from '../../models/app-config.model';
import { BCMCLPUser, CLPUser, TeamCodes } from '../../models/clpuser.model';
import { CMClub, CMClubResponse, CMSite, CMSiteEmployees, CMSiteEmployeesListResponse, CMSiteListResponse } from '../../models/cm-club-service.model';
import { CMContractItem, CMContractListRequest, CMContractLstResponse, eContractStatus } from '../../models/cm-contract';
import { SimpleResponse, UserDD } from '../../models/generic-response.model';
import { CMClubService } from '../../services/cm-club.service';
import { CMContractService } from '../../services/cm-contract.service';
import { NotificationService } from '../../services/notification.service';
import { AppConfigService } from '../../services/shared/app-config.service';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { TeamOfficeService } from '../../services/team-office.service';
import { UserService } from '../../services/user.service';
declare var $: any;

@Component({
    selector: 'app-cm-contract-list',
    templateUrl: './cm-contract-list.component.html',
    styleUrl: './cm-contract-list.component.css'
})
export class CmContractListComponent {
    @ViewChild("dropdownlist") public dropdownlist: DropDownListComponent;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<CMContractItem>;
    displayedColumns: string[] = ['Members', 'productDesc', 'siteName', 'status', 'dtCreated', 'dtContractStart', 'dtContractEnd', 'dtPaymentBegins', 'dteSig', 'fees'];
    encryptedUser: string;
    user: BCMCLPUser;
    userAI: CLPUser;
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    isAI: boolean = false;
    clpCompanyId: number = 0;
    curTeam: TeamCodes = <TeamCodes>{};
    showSpinner: boolean;
    clubConfig: CMClub;
    clpUsersDD: UserDD[];
    curSiteSub: Subscription;
    curSite: CMSite = <CMSite>{};
    sites: CMSite[];
    selectedUserIDs = [];
    selectedSiteIDs = [];
    class1Code: number;
    teamSub: Subscription;
    customFilter: boolean = false
    createDateFilters = [
        {
            "value": 1,
            "name": "This Month"
        },
        {
            "value": 2,
            "name": "Last Month"
        },
        {
            "value": 3,
            "name": "Custom"
        }
    ]
    selectedCtDtFilter: number = 1
    customDateStr: string = '';
    currentRange = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null),
    });
    selectedCtDtFilterName: string;
    selectedCustomdtStart: string = ''
    selectedCustomdtEnd: string = ''
    allContractStatus = [
        {
            "value": 10,
            "name": "--All--"
        },
        {
            "value": 0,
            "name": "None"
        }, {
            "value": 1,
            "name": "Configured"
        }, {
            "value": 2,
            "name": "In Progress"
        }, {
            "value": 3,
            "name": "Members Signed"
        }, {
            "value": 4,
            "name": "Waiting Countersign"
        }, {
            "value": 5,
            "name": "Active"
        }, {
            "value": 6,
            "name": "In Active"
        }]
    selectedConStatus: number = eContractStatus.All
    selectedRowIndex: any;
    contractList: CMContractItem[];
    pageSize: number = 10;
    constructor(private _localService: LocalService,
        private _teamOfficeService: TeamOfficeService,
        private datePipe: DatePipe,
        private userService: UserService,
        private _utilityService: UtilityService,
        private clubService: CMClubService,
        private _notifyService: NotificationService,
        private _globalService: GlobalService,
        private contractService: CMContractService,
        private _router: Router,
        private _appconfigService: AppConfigService,
    ) {

        this._localService.setShowMenu(true)
        this._localService.setRouteName("cm-contracts")
        this._localService.setIsCmManager(true);
        this._localService.updateTeamDD(true)
        this.formatMems([
            "Abigail, Alsop:True",
            "dev, vrat:False",
            "amit, chaudhary:False",
            ,
            "dell, sony:False"
        ])
        this.loadPageSize()
    }

    ngAfterViewInit() {
        this.encryptedUser = localStorage.getItem("token");
        if (this.encryptedUser) {
            this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
            this.authenticateR().then(() => {
                if (this.user) {
                    
                    this.loadData()
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }
    }
    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false;
    }

    loadPageSize() {
        const storedPageSize = localStorage.getItem('pageSize');
        if (storedPageSize) {
            this.pageSize = +storedPageSize;
        }
    }

    onPageChange(event: any) {
        localStorage.setItem('pageSize', event.pageSize);
    }

    createDtFilterChange(event): void {
        console.log(event)
        let value = event

        if (value != 3) {
            this.selectedCtDtFilter = +value
            this.cMContractGetList();
        }
        else {
            this.customDateStr = ''
            this.customFilter = true;
        }
    }

    dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
        var startDt = dateRangeStart.value;
        var endDt = dateRangeEnd.value;
        if (startDt) {
            let customdtStart = this.datePipe.transform(startDt, 'MMddyyyy');
            this.selectedCustomdtStart = customdtStart;

        }
        if (endDt) {
            let customdtEnd = this.datePipe.transform(endDt, 'MMddyyyy');
            this.selectedCustomdtEnd = customdtEnd;
            this.selectedCtDtFilter = 3;
            this.selectedCtDtFilterName = "Custom"
            if (this.selectedCustomdtStart && this.selectedCustomdtEnd != null) {
                this.cMContractGetList();
            }
        }
    }

    revertCustomFinalizeddtFilter() {
        this.customFilter = false;
        this.selectedCtDtFilter = 1;
        this.selectedCtDtFilterName = "This Month";
        this.selectedCustomdtStart = ''
        this.selectedCustomdtEnd = ''
        this.cMContractGetList();
    }

    private async authenticateR() {

        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadData() {
        this.teamSub = this._teamOfficeService.curTeam.subscribe(async (data: any) => {
            if (data != 0 && data != null) {
                this.curTeam = data
                if (this.curTeam?.teamCode > 0) {

                    await this.CMClub_Get();
                    await this.siteSearch()
                    await this.cLPUserGetListByTeamCode(this.clpCompanyId, this.curTeam?.teamCode);
                    this.cMContractGetList();
                }
            }
            else {
                this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
                if (this.curTeam?.teamCode > 0) {
                    //this.selectedSiteID = 0
                    //this.selectedUserID = 0
                    await this.CMClub_Get();
                    await this.siteSearch();
                    await this.cLPUserGetListByTeamCode(this.clpCompanyId, this.curTeam?.teamCode);
                    this.cMContractGetList();
                }
            }
        })
        this.curSiteSub = this.clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = await value
            if (this.curSite == null) {
                this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')

            }

        })
    }

    async siteSearch() {
        if (this.clubConfig?.clubId > 0) {
            await this.clubService.siteSearch(this.encryptedUser, 0, this.clubConfig?.clubId, 0, 0, this.isAI).
                then(async (result: CMSiteListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.sites = response?.sites;
                        this.sites.unshift({
                            siteID: 0,
                            clubID: 0,
                            class1Code: 0,
                            clpcompanyID: 0,
                            siteName: "--Select--",
                            teamCode: 0,
                            address: "",
                            city: "",
                            state: "",
                            country: "",
                            zip: "",
                            taxRate: 0,
                            scheduleSiteVisitURL: "",
                            scheduleOpenHouseURL: "",
                            vesselsURL: "",
                            kioskURL: "",
                            lnkCustom1URL: "",
                            lnkCustom2URL: "",
                            lnkCustom3URL: "",
                            lnkCustom4URL: "",
                            lnkCustom5URL: "",
                            mktCustomText1: "",
                            mktCustomText2: "",
                            mktCustomText3: "",
                            mktCustomText4: "",
                            mktCustomText5: "",
                            mktMultiText1: "",
                            mktMultiText2: "",
                            mktMultiText3: "",
                            mktMultiText4: "",
                            mktMultiText5: "",
                            engDashCustomMMT1D1: "",
                            engDashCustomMMT1D2: "",
                            engDashCustomMMT1D3: "",
                            engDashCustomMMT1D4: "",
                            engDashCustomMMT1D5: "",
                            engDashCustomMMT1D6: "",
                            engDashCustomMMT1D7: "",
                            engDashCustomMMT1D8: "",
                            engDashCustomMMT1D9: "",
                            engDashCustomMMT1D10: "",
                            dtCreated: "",
                            dtModified: "",
                            newLead: 0,
                            newMember: 0,
                            contractSent: 0,
                            contractOpen: 0,
                            waitingCountersign: 0,
                            contractExpiration: 0,
                            contactUpdate: 0,
                            referralRequest: 0,
                            teamCodeDisplay: "",
                            class1Display: "",
                            phLocation: "",
                        })
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async CMClub_Get() {
        if (this.curTeam?.teamCode > 0) {
            this.showSpinner = true
            await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, this.curTeam?.teamCode, this.isAI).
                then(async (result: CMClubResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.clubConfig = response?.clubConfig
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async cLPUserGetListByTeamCode(clpCompanyId, teamCode) {
        this.showSpinner = true
        await this.userService.cLPUserGetListByTeamCode(this.encryptedUser, clpCompanyId, teamCode,this.isAI).
            then(async (result: UserDD[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.clpUsersDD = response
                    this.clpUsersDD.unshift({
                        value: 0,
                        text: "--Select--"
                    })
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("contact-task.getLoadUsers", err.message, null,
                    'clpCompanyId: ' + this.clpCompanyId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cMContractGetList() {
        let cMContractListRequest = <CMContractListRequest>{}
        cMContractListRequest.timeFrame = this.selectedCtDtFilter
        cMContractListRequest.contractStatus = this.selectedConStatus
        cMContractListRequest.users = this.selectedUserIDs
        cMContractListRequest.sites = this.selectedSiteIDs
        cMContractListRequest.strdtStart = this.selectedCustomdtStart
        cMContractListRequest.strdtEnd = this.selectedCustomdtEnd
        cMContractListRequest.clubId = this.clubConfig?.clubId
        this.showSpinner = true
        await this.contractService.cMContractGetList(this.encryptedUser, this.user?.cLPUserID, cMContractListRequest, this.isAI).
            then(async (result: CMContractLstResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.contractList = response?.contractList
                    this.dataSource = new MatTableDataSource(this.contractList);
                    setTimeout(() => {
                        this.dataSource.paginator = this.paginator;
                    }, 1000)
                    this.dataSource.sort = this.sort;

                    this.dataSource.filterPredicate =
                        (data: CMContractItem, filter: string) => {
                            let values = filter.split(' ');

                            let finalValues: boolean[] = [];
                            let final: boolean = true;

                            values.forEach((value) => {
                                finalValues.push(data.cmContractID.toString().indexOf(value) != -1 ||
                                    data.contactName.trim().toLowerCase().indexOf(value) != -1 ||
                                    this.formatDate(data.dtCreated).toLowerCase().indexOf(value) != -1 ||
                                    this.formatDate(data.dtContractEnd).toLowerCase().indexOf(value) != -1 ||
                                    this.formatDate(data.dtContractStart).toLowerCase().indexOf(value) != -1 ||
                                    this.formatDate(data.dteSig).toLowerCase().indexOf(value) != -1 ||
                                    this.formatDate(data.dtPaymentBegins).toLowerCase().indexOf(value) != -1 ||
                                    data.fees.trim().toLowerCase().indexOf(value) != -1 ||
                                    this.formatMems(data.Members).trim().toLowerCase().indexOf(value) != -1 ||
                                    data.siteName.trim().toLowerCase().indexOf(value) != -1 ||
                                    data.status.trim().toLowerCase().indexOf(value) != -1
                                );
                            });

                            //only return if all values are true
                            finalValues.forEach(f => {
                                if (!f)
                                    final = false;
                            });

                            return final;
                        };
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract-list.cMContractGetList", err.message, null,
                    'clpCompanyId: ' + this.clpCompanyId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim().toLowerCase();
        if (this.dataSource) {
            this.dataSource.filter = filterValue;
        }
    }

    async getSearchFilter() {
        //await this.siteEmployeesSearch(false);
    }

    getSitenName(siteID: number): string {
        return this.sites?.filter(x => x.siteID == siteID)[0]?.siteName;
    }

    selectRow(row: any): void {
        if (row) {
            let url = "cm-contract/" + row?.contactID + '?r=' + this.encryptedUser
            window.open(url, '_blank')
            this.selectedRowIndex = row.sn;
        }
    }

    onValueChange(multiSelect: MultiSelectComponent) {
        multiSelect.clearFilter();
        this.cMContractGetList();
    }

    formatMems(members) {
        let primaryMem = '';
        let otherMems = '';
        members.forEach((mem: string) => {
            if (mem?.includes('True')) {
                primaryMem = mem?.split(':')[0];
            }
            else {
                otherMems = `${otherMems}<div class='members-other'>${mem?.split(':')[0]}</div>`;
            }

        })
        return primaryMem ? `<div>${primaryMem}</div>  ${otherMems}` : '';
    }

    formatDate(dateString) {
        if (dateString != '') {
            const date = new Date(dateString);
            const formattedDate = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;

            return formattedDate;
        }
        else return '';
    }

    getDate(value) {
        if (value == '0001-01-01T00:00:00') {
            return '';
        }
        else
            return value;
    }
}
