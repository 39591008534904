import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CountryPlaceholder } from '../models/country-state.model';
import { DropDownItem, keyValue } from '../models/generic-response.model';
import { delayedRetryHttp } from './shared/delayed-retry';
import { UtilityService } from './shared/utility.service';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';

@Injectable({
  providedIn: 'root'
})
export class CountryStateService {

  private baseUrl: string;
  private api: string = "api/CountryState";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getCountryGetList(encryptedUser: string,isAI:boolean=false): Promise<DropDownItem[] | void> {
    const http$ = await this.httpClient
        .get<DropDownItem[]>(`${this.baseUrl}/Country_GetList?isAI=${isAI}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CountryStateService", "getCountryGetList") });

    return http$;
  }

  async getCountryStateList(encryptedUser: string, countryCode: string): Promise<DropDownItem[] | void> {
    const http$ = await this.httpClient
      .get<DropDownItem[]>(`${this.baseUrl}/Country_StateList?countryCode=`+countryCode, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CountryStateService", "getCountryGetList") });

    return http$;
    }

    async getListWithPlaceholder(encryptedUser: string, isAI : boolean): Promise<CountryPlaceholder[] | void> {
        const http$ = await this.httpClient
            .get<CountryPlaceholder[]>(`${this.baseUrl}/Country_GetListWithPlaceholder?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CountryStateService", "getCountryGetList") });

        return http$;
    }


    parseSimplePhone(phone: string, dialCode: CountryCode) {
        if (phone && phone != '') {
            try {
                const phoneNumber = parsePhoneNumber(phone, dialCode);
                let value = phoneNumber.formatNational();
                if (value && value.startsWith('0'))
                    value = value.replace('0', '');
                return value;
            }
            catch {
                return phone;
            }
        }
        else
            return '';
    }

    replaceZero(value) {
        let final = '';

        for (let i = 0; i < value.length; i++) {
            if (value[i] == ' ') {
                final += ' ';
            }
            else {
                if (!isNaN(+value[i])) {
                    final += '0';
                }
                else {
                    final += value[i];
                }
            }
        }
        return final;
    }
}
