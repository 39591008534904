<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Dashboard</h3>
    </div>
    <div class="common-header">
        <div class="date-row">
            <div class="date-row-column">
                <select class="form-control" [(ngModel)]="selectedCtDtFilter" (change)="filterDashboard($event.target.value)" style="width:auto; background:none; border:none; padding-left:0px; color:#212529; font-weight:500;">
                    <option value="1">This Month</option>
                    <option value="2">Last Month</option>
                    <option value="3">Custom</option>
                </select>
            </div>
            <div class="date-row-column">
                <div class="custom-date" *ngIf="customFilter">
                    <div class="display-flex">
                        <span class="date-range">Custom Range</span>
                        <mat-form-field appearance="outline">
                            <mat-date-range-input [rangePicker]="dateRangePicker" [formGroup]="currentRange">
                                <input matStartDate placeholder="Start date" formControlName="start" #dateRangeStart>
                                <input matEndDate placeholder="End date" formControlName="end" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle class="custom-daterange-toggle" matPrefix [for]="dateRangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                            <span *ngIf="currentRange.controls.start.hasError('matStartDateInvalid')"><mat-error>Invalid start date</mat-error></span>
                            <span *ngIf="currentRange.controls.end.hasError('matEndDateInvalid')"><mat-error>Invalid end date</mat-error></span>
                        </mat-form-field>
                        <i class="fa fa-undo" *ngIf="customFilter" (click)="revertCustomFinalizeddtFilter()" style="font-size:20px; margin-left:10px;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="alignment-content">
        <div class="flex-row">
            <div class="flex-column1">
                <div class="inner-flex-row">
                    <div class="common-card">
                        <div class="card-panel">
                            <h6>Contracts</h6>
                            <div class="hightlight-text">
                                <h1>{{cmDashBoardResponse?.totalContracts}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="common-card">
                        <div class="card-panel">
                            <h6>Fees Collected</h6>
                            <div class="hightlight-text">
                                <h1>{{cmDashBoardResponse?.totalFees | currency}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="common-card">
                        <div class="card-panel">
                            <h6>By Agent</h6>
                            <div class="table-panel">
                                <div class="table-body">
                                    <table mat-table [dataSource]="dataSourceAgent" class="">
                                        <ng-container matColumnDef="userName">
                                            <th mat-header-cell *matHeaderCellDef>Agent</th>
                                            <td mat-cell *matCellDef="let element">{{element.userName}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="totalContracts">
                                            <th mat-header-cell *matHeaderCellDef>Contracts</th>
                                            <td mat-cell *matCellDef="let element">{{element.totalContracts}}</td>
                                        </ng-container>

                                        <!-- Assign columns to the table -->
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsAgent"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsAgent;"></tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="common-card">
                        <div class="card-panel">
                            <h6>By Contract Status</h6>
                            <div class="table-panel">
                                <div class="table-body">
                                    <table mat-table [dataSource]="dataSourceStatus" class="">
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef>Status</th>
                                            <td mat-cell *matCellDef="let element">{{element.status}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="count">
                                            <th mat-header-cell *matHeaderCellDef>Contracts</th>
                                            <td mat-cell *matCellDef="let element">{{element.count}}</td>
                                        </ng-container>

                                        <!-- Assign columns to the table -->
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsStatus"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsStatus;"></tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="common-card">
                        <div class="card-panel">
                            <h6>By Site</h6>
                            <div class="table-panel">
                                <div class="table-body">
                                    <table mat-table [dataSource]="dataSourceSite" class="">
                                        <ng-container matColumnDef="siteName">
                                            <th mat-header-cell *matHeaderCellDef>Site</th>
                                            <td mat-cell *matCellDef="let element">{{element.siteName}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="contractCounts">
                                            <th mat-header-cell *matHeaderCellDef>Contracts</th>
                                            <td mat-cell *matCellDef="let element">{{element.contractCounts}}</td>
                                        </ng-container>

                                        <!-- Assign columns to the table -->
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsSite"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsSite;"></tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="common-card">
                        <div class="card-panel">
                            <h6>Collected Fees</h6>
                            <div class="table-panel">
                                <div class="table-body">
                                    <table mat-table [dataSource]="dataSourceClubFee" class="">
                                        <ng-container matColumnDef="totalContractFeePaid">
                                            <th mat-header-cell *matHeaderCellDef>Total</th>
                                            <td mat-cell *matCellDef="let element">{{element.totalContractFeePaid | currency}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="cMContractCounts">
                                            <th mat-header-cell *matHeaderCellDef>Contracts</th>
                                            <td mat-cell *matCellDef="let element">{{element.cMContractCounts}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="avg">
                                            <th mat-header-cell *matHeaderCellDef>Avg.</th>
                                            <td mat-cell *matCellDef="let element">{{element.avg | currency}}</td>
                                        </ng-container>

                                        <!-- Assign columns to the table -->
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-row pair-card">
            <div class="flex-column">
                <div class="common-card">
                    <div class="card-panel">
                        <h6>Monthly Fees</h6>
                        <h1>$2,657</h1>
                        <kendo-chart>
                            <kendo-chart-series>
                                <kendo-chart-category-axis>
                                    <kendo-chart-category-axis-item [visible]="false"></kendo-chart-category-axis-item>
                                </kendo-chart-category-axis>
                                <kendo-chart-value-axis>
                                    <kendo-chart-value-axis-item [visible]="false"></kendo-chart-value-axis-item>
                                </kendo-chart-value-axis>
                                <kendo-chart-series-item type="column" min="0"
                                                         [data]="cashFlowData" color="#3779f8"
                                                         field="amount" categoryField="period" summaryField="summary">
                                </kendo-chart-series-item>

                            </kendo-chart-series>
                        </kendo-chart>
                    </div>
                </div>
            </div>
            <div class="flex-column1">
                <div class="inner-flex-row">
                    <div class="common-card full-width">
                        <div class="card-panel">
                            <h6>Total Sales</h6>
                            <h1>$23,657</h1>
                            <kendo-chart>
                                <kendo-chart-category-axis>
                                    <kendo-chart-category-axis-item [categories]="categories"></kendo-chart-category-axis-item>
                                </kendo-chart-category-axis>
                                <kendo-chart-series>
                                    <kendo-chart-category-axis>
                                        <kendo-chart-category-axis-item [visible]="false"></kendo-chart-category-axis-item>
                                    </kendo-chart-category-axis>
                                    <kendo-chart-value-axis>
                                        <kendo-chart-value-axis-item [visible]="false"></kendo-chart-value-axis-item>
                                    </kendo-chart-value-axis>
                                    <!-- Line series -->
                                    <kendo-chart-series-item type="line"
                                                             [style]="style"
                                                             [data]="originalData"
                                                             [markers]="{ visible: false }"
                                                             [color]="'#007bff'"></kendo-chart-series-item>
                                    <!-- Area series -->
                                    <kendo-chart-series-item [line]="{ style: styleArea }"
                                                             type="area"
                                                             [data]="originalData"
                                                             [color]="'#007bff'"
                                                             [markers]="{ visible: false }">
                                    </kendo-chart-series-item>
                                </kendo-chart-series>
                            </kendo-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-section">
            <div class="card-panel">
                <div class="table-panel">
                    <div class="card-header">
                        <h5>Highlights</h5>
                    </div>
                    <div class="table-body">
                        <table mat-table [dataSource]="dataSource" class="">
                            <!-- Define columns -->
                            <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
                                <th mat-header-cell *matHeaderCellDef>{{ col | titlecase }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[col] }}
                                    <ng-container *ngIf="col != 'difference'; else difference">
                                        {{element[col]}}
                                    </ng-container>
                                    <ng-template #difference>
                                        {{element[col]}}<img src="../../../assets/img/up-arrow.svg" />
                                    </ng-template>
                                </td>
                            </ng-container>
                            <!-- Assign columns to the table -->
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
