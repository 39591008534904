import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Search, SearchListResponse } from '../../models/search.model';
import { delayedRetryHttp } from './delayed-retry';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class SearchContactService {


  private baseUrl: string;
  private baseUrlCompany: string;
  private baseUrlLead: string;
    private api: string = "api/CMContract";
  private apiComp: string = "api/SearchCompany";
  private apiLead: string = "api/LeadSetting";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
    this.baseUrlCompany = _baseUrl + this.apiComp;
    this.baseUrlLead = _baseUrl + this.apiLead;
  }


    async getContactSearchData(encryptedUser: string, userId: number, search: Search, isAI: boolean): Promise<SearchListResponse | void> {
    const a = await this.httpClient
        .post<SearchListResponse>(`${this.baseUrl}/GetSearchData/${userId}?isAI=${isAI}`, search, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, search, "r - " + encryptedUser + "," + "userId" + userId, encryptedUser, "SearchContactService", "getContactSearchData"); });
    return a;
  }

}
