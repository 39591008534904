import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AutomationProcessDD, CMClub, CMClubResponse, CMClubSiteConfig, CMClubSiteConfigListResponse, FilterTeam_Load_GeneralResponse, CMSite, CMSiteEmployees, CMSiteEmployeesListResponse, CMSiteEmployeesUdtRequest, CMSiteListResponse, SiteDDListResponse, CMProcessorResponse, CMProcessor, CMProcessorVendorListResponse, CMSiteExistsResponse } from '../models/cm-club-service.model';
import { IntDropDownItemListResponse, keyValue, SimpleResponse } from '../models/generic-response.model';
import { delayedRetryHttp } from './shared/delayed-retry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class CMClubService {

    private baseUrl: string;
    private api: string = 'api/CMClub';
    clubId = new BehaviorSubject<number>(0)
    curClubId = this.clubId.asObservable()
    site = new BehaviorSubject<{}>(null)
    curSite = this.site.asObservable()
    baseUrlAI: string;



    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    setCurClubId(value) {
        this.clubId.next(value);
    }

    setCurSite(value) {
        this.site.next(value);
    }



    async CMClub_Update(encryptedUser: string, clubConfig: CMClub, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMClub_Update?isAI=${isAI}`, clubConfig, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "clubConfigUpdate"); });
        return a;
    }

    async clubSiteConfigUpdate(encryptedUser: string, siteFiedName: CMClubSiteConfig, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMClubSiteConfig_Update?isAI=${isAI}`, siteFiedName, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMClubSiteConfig_Update"); });
        return a;
    }

    async CMClub_Get(encryptedUser: string, clpcompanyId: number, teamCode: number, isAI: boolean): Promise<CMClubResponse | void> {
        const a = await this.httpClient
            .get<CMClubResponse>(`${this.baseUrl}/CMClub_Get/${clpcompanyId}/${teamCode}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMClub_Get"); });
        return a;
    }

    async clubSiteConfigSearch(encryptedUser: string, id: number, clubID: number, teamCode: number, clpcompanyID: number, isAI: boolean): Promise<CMClubSiteConfigListResponse | void> {
        const a = await this.httpClient
            .get<CMClubSiteConfigListResponse>(`${this.baseUrl}/CMClubSiteConfig_Search?id=${id}&clubID=${clubID}&teamCode=${teamCode}&clpcompanyID=${clpcompanyID}&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "clubSiteConfigSearch"); });
        return a;
    }

    async cm_Webform_GetList(encryptedUser: string, clpcompanyId: number, clubId: number, isAI: boolean): Promise<keyValue[] | void> {
        const a = await this.httpClient
            .get<keyValue[]>(`${this.baseUrl}/CM_Webform_GetList/${clpcompanyId}/${clubId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "webFormListDDGet"); });
        return a;
    }

    async cm_CampaignTemplate_GetList(encryptedUser: string, companyId: number, clubId: number, isAI: boolean): Promise<AutomationProcessDD[] | void> {
        const a = await this.httpClient
            .get<AutomationProcessDD[]>(`${this.baseUrl}/CM_CampaignTemplate_GetList/${companyId}/${clubId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "automationProcessListGetByClub"); });
        return a;
    }

    async siteSearch(encryptedUser: string, siteID: number, clubID: number, class1Code: number, clpcompanyID: number, isAI: boolean): Promise<CMSiteListResponse | void> {
        const a = await this.httpClient
            .get<CMSiteListResponse>(`${this.baseUrl}/CMSite_Search?siteID=${siteID}&clubID=${clubID}&class1Code=${class1Code}&clpcompanyID=${clpcompanyID}&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "siteSearch"); });
        return a;
    }


    async CMSite_Update(encryptedUser: string, site: CMSite, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMSite_Update?isAI=${isAI}`, site, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMSite_Update"); });
        return a;
    }

    async CMSite_Engage_Update(encryptedUser: string, site: CMSite, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMSite_Engage_Update?isAI=${isAI}`, site, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMSite_Engage_Update"); });
        return a;
    }

    async CMSite_Trigger_Update(encryptedUser: string, site: CMSite, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMSite_Trigger_Update?isAI=${isAI}`, site, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMSite_Trigger_Update"); });
        return a;
    }

    async CMSite_Update_Mkt(encryptedUser: string, site: CMSite, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMSite_Update_Mkt?isAI=${isAI}`, site, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMSite_Update_Mkt"); });
        return a;
    }

    async CMSite_Update_Links(encryptedUser: string, site: CMSite, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMSite_Update_Links?isAI=${isAI}`, site, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMSite_Update_Links"); });
        return a;
    }

    async CMSite_Update_Gen(encryptedUser: string, site: CMSite, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMSite_Update_Gen?isAI=${isAI}`, site, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMSite_Update_Gen"); });
        return a;
    }

    async siteEmployeesBulkInsert(encryptedUser: string, siteEmployees: CMSiteEmployeesUdtRequest[], isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMSiteEmployees_Bulk_Insert?isAI=${isAI}`, siteEmployees, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "siteEmployeesBulkInsert"); });
        return a;
    }

    async siteEmployeesSearch(encryptedUser: string, clpcompanyID: number, siteID: number, clubID: number, class1Code: number, userId: number = 0, role: string = "", isAI: boolean): Promise<CMSiteEmployeesListResponse | void> {
        const a = await this.httpClient
            .get<CMSiteEmployeesListResponse>(`${this.baseUrl}/CMSiteEmployees_Search/${clpcompanyID}?siteID=${siteID}&clubID=${clubID}&class1Code=${class1Code}&userId=${userId}&role=${role}&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMSiteEmployees_Search"); });
        return a;
    }

    async CMSiteEmployees_GetSigningOfficers(encryptedUser: string, clpcompanyID: number, siteID: number, clubID: number, isAI: boolean): Promise<CMSiteEmployeesListResponse | void> {
        const a = await this.httpClient
            .get<CMSiteEmployeesListResponse>(`${this.baseUrl}/CMSiteEmployees_GetSigningOfficers/${clpcompanyID}?siteID=${siteID}&clubID=${clubID}&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMSiteEmployees_GetSigningOfficers"); });
        return a;
    }

    async clubGetList(encryptedUser: string, clpcompanyID: number, isAI: boolean): Promise<IntDropDownItemListResponse | void> {
        const a = await this.httpClient
            .get<IntDropDownItemListResponse>(`${this.baseUrl}/CMClub_GetList/${clpcompanyID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "clubGetList"); });
        return a;
    }

    async clubGetByID(encryptedUser: string, clubID: number, isAI: boolean): Promise<CMClubResponse | void> {
        const a = await this.httpClient
            .get<CMClubResponse>(`${this.baseUrl}/CMClub_GetByID/${clubID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "clubGetByID"); });
        return a;
    }

    async CMSiteEmployees_Update(encryptedUser: string, siteEmployees: CMSiteEmployees, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMSiteEmployees_Update?isAI=${isAI}`, siteEmployees, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMSiteEmployees_Update"); });
        return a;
    }

    async siteEmployeesRemove(encryptedUser: string, empId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMSiteEmployees_Remove/${empId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "siteEmployeesRemove"); });
        return a;
    }

    async filterTeamLoadGeneral(encryptedUser: string, clpcompanyId: number, selectTeamCode: number, isReLoad: boolean, isAI: boolean): Promise<FilterTeam_Load_GeneralResponse | void> {
        const a = await this.httpClient
            .get<FilterTeam_Load_GeneralResponse>(`${this.baseUrl}/FilterTeam_Load_General/${clpcompanyId}?selectTeamCode=${selectTeamCode}&isReLoad=${isReLoad}&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "filterTeamLoadGeneral"); });
        return a;
    }

    async Site_GetDD(encryptedUser: string, clpcompanyID: number, contactId: number, isAI: boolean): Promise<SiteDDListResponse | void> {
        const a = await this.httpClient
            .get<SiteDDListResponse>(`${this.baseUrl}/Site_GetDD/${clpcompanyID}/${contactId}/${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "Site_GetDD"); });
        return a;
    }

    async cMProcessor_Load(encryptedUser: string, clubId: number, isAI: boolean): Promise<CMProcessorResponse | void> {
        const a = await this.httpClient
            .get<CMProcessorResponse>(`${this.baseUrl}/CMProcessor_Load/${clubId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMProcessor_Load"); });
        return a;
    }

    async cMProcessor_Update(encryptedUser: string, p: CMProcessor, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMProcessor_Update?isAI=${isAI}`, p, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "CMProcessor_Update"); });
        return a;
    }

    async rEF_CMProcessorVendor_Get(encryptedUser: string, isAI: boolean): Promise<CMProcessorVendorListResponse | void> {
        const a = await this.httpClient
            .get<CMProcessorVendorListResponse>(`${this.baseUrl}/REF_CMProcessorVendor_Get/?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "REF_CMProcessorVendor_Get"); });
        return a;
    }

    async cMSite_Exits(encryptedUser: string, class1Code: number, clpcompanyID: number, isAI: boolean): Promise<CMSiteExistsResponse | void> {
        const a = await this.httpClient
            .get<CMSiteExistsResponse>(`${this.baseUrl}/CMSite_Exits/${class1Code}/${clpcompanyID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "REF_CMProcessorVendor_Get"); });
        return a;
    }

    async employees_ClubLevel(encryptedUser: string, empId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMSiteEmployees_ClubLevel/${empId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "siteEmployeesRemove"); });
        return a;
    }

    async employeesClone_Site(encryptedUser: string, clpcompanyId: number,clubId: number, siteId: number,toSiteId : number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMSiteEmployees_CloneSite/${clpcompanyId}/${clubId}/${siteId}/${toSiteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "employeesClone_Site"); });
        return a;
    }

    async cmSiteCloneTrigger(encryptedUser: string, clpcompanyId: number, clubId: number, siteId: number, toSiteId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMSite_CloneTriggers/${clpcompanyId}/${clubId}/${siteId}/${toSiteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "employeesClone_Site"); });
        return a;
    }

    async cMSite_CloneLinks(encryptedUser: string, clpcompanyId: number, clubId: number, siteId: number, toSiteId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMSite_CloneLinks/${clpcompanyId}/${clubId}/${siteId}/${toSiteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "employeesClone_Site"); });
        return a;
    }

    async cMSiteCloneEngageDash(encryptedUser: string, clpcompanyId: number, clubId: number, siteId: number, toSiteId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMSiteCloneEngageDash/${clpcompanyId}/${clubId}/${siteId}/${toSiteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "employeesClone_Site"); });
        return a;
    }
}
