<div class="main-wrapper-section">
  <div class="common-header">
    <h3>General</h3>
  </div>
  <div class="card-section">
    <div class="card-panel">
      <p class="heading-text">The following fields are used to customize each club. <a href="javascript:void();">Full Placeholder List</a></p>

      <form [formGroup]="clubConfigForm" (ngSubmit)="onSubmitClubConfigForm()">
          <div class="flex-form">
              <div class="form-group">
                  <label>Club Name</label>
                  <input class="form-control" formControlName="clubName" maxlength="50" />
                  <div *ngIf="clubConfigForm.controls.clubName.errors && (clubConfigForm.controls.clubName.touched || clubConfigForm.controls.clubName.dirty)">
                      <div class="login-error" *ngIf="clubConfigForm.controls.clubName.errors.required">Club name is required. </div>
                  </div>
              </div>
              <div class="form-group">
                  <label>Team</label>
                  <kendo-combobox *ngIf="userRole >= 3 || slurpyUserId > 0" class="form-control min-width300" [data]="teamFilterDD"
                                  [valuePrimitive]="true"
                                  textField="display"
                                  [kendoDropDownFilter]="filterSettings"
                                  [filterable]="true"
                                  formControlName="teamCode"
                                  (valueChange)="onChangeTeamCode($event)"
                                  valueField="teamCode">
                  </kendo-combobox>
                  <div *ngIf="userRole >= 3 || slurpyUserId > 0">
                      <div *ngIf="clubConfigForm.controls.teamCode.errors && (clubConfigForm.controls.teamCode.touched || clubConfigForm.controls.teamCode.dirty)">
                          <div class="login-error" *ngIf="clubConfigForm.controls.teamCode.errors.required">Team is required.</div>
                      </div>
                  </div>
                  <p *ngIf="userRole < 3">{{curTeam?.display}}</p>
              </div>
              <div class="form-group" *ngIf="userRole >= 3 || slurpyUserId > 0">
                  <label>Owner</label>
                  <kendo-combobox class="form-control min-width300" [data]="userList"
                                  [valuePrimitive]="true"
                                  textField="text"
                                  [kendoDropDownFilter]="filterSettings"
                                  [filterable]="true"
                                  formControlName="ownerUserId"
                                  valueField="value">
                  </kendo-combobox>
                  <!--<p *ngIf="user?.userRole < 3">{{selectedUser?.text}}</p>-->
              </div>
          </div>
          <div class="flex-form">
              <div class="form-group">
                  <label>EIN</label>
                  <input class="form-control" formControlName="clubEIN" maxlength="50" />
              </div>
              <div class="form-group">
                  <label>Region</label>
                  <input class="form-control" formControlName="clubRegion" maxlength="50" />
              </div>
              <div class="form-group">
                  <label>Territory</label>
                  <input class="form-control" formControlName="clubTerritory" maxlength="50" />
              </div>
          </div>
          <div class="flex-form">
              <div class="form-group flex-none min-width300">
                  <label>Address</label>
                  <input class="form-control" formControlName="clubAddress" maxlength="50" />
              </div>
              <div class="form-group flex-none min-width300">
                  <label>City</label>
                  <input class="form-control" formControlName="clubCity" maxlength="50" />
              </div>
              <div class="form-group">
                  <label>State</label>
                  <kendo-combobox [data]="stateList"
                                  class="form-control min-width300"
                                  [valuePrimitive]="true"
                                  [kendoDropDownFilter]="filterSettings"
                                  [filterable]="true"
                                  [(ngModel)]="stateCode"
                                  [ngModelOptions]="{standalone: true}"
                                  textField="text"
                                  valueField="value">
                  </kendo-combobox>
              </div>
          </div>
          <div class="flex-form">
              <div class="form-group">
                  <label class="fldtitle">Zip</label>
                  <input type="text" formControlName="clubZip" maxlength="6" autocomplete="off" value="247001" class="form-control">
              </div>
              <div class="form-group">
                  <label>Country</label>
                  <kendo-combobox [data]="countryDD"
                                  class="form-control min-width300"
                                  [valuePrimitive]="true"
                                  [kendoDropDownFilter]="filterSettings"
                                  [filterable]="true"
                                  textField="text"
                                  [(ngModel)]="countryCode"
                                  [ngModelOptions]="{standalone: true}"
                                  valueField="value"
                                  (valueChange)="onChangeCountry($event)">
                  </kendo-combobox>
              </div>
              <div class="form-group">
                  <div class="custom-control custom-switch payment-process-check">
                      <input type="checkbox" class="custom-control-input" id="processorTestMode" formControlName="isContractReview">
                      <label class="custom-control-label custom-control-label-disabled" for="processorTestMode">Enable Contract Review</label>
                  </div>
              </div>
          </div>
          <hr />

          <div class="button-group">
              <div class="form-group">
                  <button class="primary-btn" type="submit">Save <i *ngIf="submitted" class="fas fa-spinner fa-spin"></i></button>
              </div>
          </div>
      </form>

    </div>
  </div>
</div>
<!--<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>-->
