import { IntDropDownItem, SimpleResponse } from "./generic-response.model";

export interface Product {
    cmProductID: number;
    productName: string;
    clpcompanyID: number;
    clubID: number;
    siteID: number;
    class4Code: number;
    productDesc: string;
    contractTerm: string;
    daysOfWeekOnPlan: string;
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    isSunday: boolean;
    isSeasonal: boolean;
    rateIncreasePct: number;
    rateIncreaseTerm: string;
    priceSheetDescLive: string;
    priceSheetDescDraft: string;
    isShowPricing: boolean;
    isIncludeInPriceSheet: boolean;
    isDefault: boolean;
    isActive: boolean;
    intMaxMembers: number;
    sOrder: number;
    documents: ProductDoc[];
    fees: Fee[];
    class4Display: string;
    isDeleted: boolean;
}

export interface SimpleProduct {
    productID: number;
    productName: string;
}

export interface ProductDoc {
    cmProductID: number;
    cmProductDocID: number;
    cmProductName: string;
    mailMergeTemplateID: number;
    mailMergeTemplate: string;
    sOrder: number;
}

export interface Fee {
    cmProductID: number;
    cmProductFeeID: number;
    cmFeeName: string;
    feeAmount: number;
    isCollectNow: boolean;
    isVariable: boolean;
    isTaxable: boolean,
    isRecurring: boolean;
    recursEvery: number; //(1 = day, 2 = month, 3 = year)
    recursEveryInterval: number; //ex: 1 day, 3 weeks, 6 months, 1 year
    isPaymentSplitable: boolean; //can it be split betweem payment methods
    memberSplitable: number; //1 = paid by one member, 2 = split by each member, 3 = paid by every member
    sOrder: number;
}

export interface ProductResponse extends SimpleResponse {
    products: Product[];
}

export interface SimpleProductResponse extends SimpleResponse {
    products: SimpleProduct[];
}

export interface RateSheetDescription {
    cmProductID: number;
    priceSheetDescLive: string;
    priceSheetDescDraft: string;
    sOrder: number;
}

export interface ProductDD extends IntDropDownItem
{
    isDefault: boolean;
}
export interface ProductDDResponse extends SimpleResponse
{
    productDD: ProductDD[];   
}
