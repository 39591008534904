import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { safehtmlpipe } from './pipes/htmlpipe/safehtmlpipe';
import { SignaturePadModule } from 'angular2-signaturepad';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { CmLocationsComponent } from './UI/cm-locations/cm-locations.component';
import { CmDashboardComponent } from './UI/cm-dashboard/cm-dashboard.component';
import { CmGeneralComponent } from './UI/cm-general/cm-general.component';
import { CmPaymentComponent } from './UI/cm-payment/cm-payment.component';
import { LeftSideMenuComponent } from './UI/left-side-menu/left-side-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenusModule } from '@progress/kendo-angular-menu';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconsModule } from '@progress/kendo-angular-icons';
import { CommonModule, DatePipe } from '@angular/common';
import { EmailService } from './services/shared/email.service';
import { GlobalService } from './services/shared/global.service';
import { LocalService } from './services/shared/local.service';
import { MenuService } from './services/shared/menu.service';
import { SearchContactService } from './services/shared/search-contact.service';
import { UtilityService } from './services/shared/utility.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { EditorModule } from '@progress/kendo-angular-editor';
import { MissingCredentialsComponent } from './UI/missing-credentials/missing-credentials.component';
import { SiteFieldsComponent } from './UI/site-fields/site-fields.component';
import { LocationLeftMenuComponent } from './UI/location-manager/location-left-menu/location-left-menu.component';
import { LmTriggersComponent } from './UI/location-manager/lm-triggers/lm-triggers.component';
import { LmLinksComponent } from './UI/location-manager/lm-links/lm-links.component';
import { LmMarketingComponent } from './UI/location-manager/lm-marketing/lm-marketing.component';
import { LmDiscountsComponent } from './UI/location-manager/lm-discounts/lm-discounts.component';
import { LmEmployeesComponent } from './UI/location-manager/lm-employees/lm-employees.component';
import { LmContractListComponent } from './UI/location-manager/lm-contract-list/lm-contract-list.component';
import { LmContractSettingsComponent } from './UI/location-manager/lm-contract-settings/lm-contract-settings.component';
import { LmEngageDashComponent } from './UI/location-manager/lm-engage-dash/lm-engage-dash.component';
import { LmVesselsComponent } from './UI/location-manager/lm-vessels/lm-vessels.component';
import { LmGeneralComponent } from './UI/location-manager/lm-general/lm-general.component';
import { LmProductsComponent } from './UI/location-manager/lm-products/lm-products.component';
import { LmDashboardComponent } from './UI/location-manager/lm-dashboard/lm-dashboard.component';
import { CmContractComponent } from './UI/cm-contract/cm-contract.component';
import { CmContractClientComponent } from './UI/cm-contract-client/cm-contract-client.component';
import { CmContractMappingComponent } from './UI/cm-contract-mapping/cm-contract-mapping.component';
import { NotificationService } from './services/notification.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CmEmployeesComponent } from './UI/cm-employees/cm-employees.component';
import { CmContractListComponent } from './UI/cm-contract-list/cm-contract-list.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CurrencyDirective } from './pipes/currency.directive';
import { AcceptCharacterDirective } from './pipes/acceptcharacter.directive';
import { PercentageDirective } from './pipes/two-digit-decima-number.directive';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { CmContractLogComponent } from './UI/cm-contract-log/cm-contract-log.component';
import { CmActivityLogComponent } from './UI/cm-activity-log/cm-activity-log.component';
import { UploadsModule } from '@progress/kendo-angular-upload';

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        CounterComponent,
        safehtmlpipe,
        FetchDataComponent,
        CmLocationsComponent,
        CmDashboardComponent,
        CmGeneralComponent,
        CmPaymentComponent,
        LeftSideMenuComponent,
        LocationLeftMenuComponent,
        LmTriggersComponent,
        LmLinksComponent,
        LmGeneralComponent,
        LmProductsComponent,
        LmMarketingComponent,
        LmDiscountsComponent,
        LmEmployeesComponent,
        LmContractListComponent,
        LmContractSettingsComponent,
        LmEngageDashComponent,
        LmVesselsComponent,
        SiteFieldsComponent,
        CmEmployeesComponent,
        CmContractListComponent,
        CmContractComponent,
        CmContractClientComponent,
        CmContractMappingComponent,
        LmDashboardComponent,
        CmActivityLogComponent,
        CmContractLogComponent,
        CurrencyDirective,
        AcceptCharacterDirective,
        PercentageDirective
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MenusModule,
        DropDownsModule,
        IconsModule,
        CommonModule,
        DropDownListModule,
        ChartsModule,
        ToastrModule.forRoot(),
        ButtonsModule,
        MatInputModule,
        MatPaginatorModule,
        ProgressBarModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatDialogModule,
        MatListModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatCardModule,
        MatTabsModule,
        MatButtonModule,
        MatIconModule,
        MatChipsModule,
        MatStepperModule,
        MatFormFieldModule,
        DateInputsModule,
        MatGridListModule,
        MatRadioModule,
        CdkStepperModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatNativeDateModule,
        EditorModule,
        CKEditorModule,
        DragDropModule,
        UploadsModule,
        CreditCardDirectivesModule,
        SignaturePadModule,
        NgxMaskModule.forRoot(maskConfig),
        RouterModule.forRoot([
            { path: '', redirectTo: 'unauthorized', pathMatch: 'full' },
            { path: 'fetch-data', component: FetchDataComponent },
            { path: 'cm-dashboard', component: CmDashboardComponent },
            { path: 'cm-locations', component: CmLocationsComponent },
            { path: 'site-fields', component: SiteFieldsComponent },
            { path: 'cm-general', component: CmGeneralComponent },
            { path: 'cm-payment', component: CmPaymentComponent },
            { path: 'cm-employees', component: CmEmployeesComponent },
            { path: 'cm-contracts', component: CmContractListComponent },
            { path: 'counter', component: CounterComponent },
            { path: 'sm-dashboard', component: LmDashboardComponent },
            { path: 'sm-general', component: LmGeneralComponent },
            { path: 'sm-products', component: LmProductsComponent },
            { path: 'sm-triggers', component: LmTriggersComponent },
            { path: 'sm-links', component: LmLinksComponent },
            { path: 'sm-marketing', component: LmMarketingComponent },
            { path: 'sm-discounts', component: LmDiscountsComponent },
            { path: 'sm-employees', component: LmEmployeesComponent },
            { path: 'sm-contracts', component: LmContractListComponent },
            { path: 'sm-contract-settings', component: LmContractSettingsComponent },
            { path: 'sm-engage-dash', component: LmEngageDashComponent },
            { path: 'sm-vessels', component: LmVesselsComponent },
            { path: 'unauthorized', component: MissingCredentialsComponent },
            { path: 'cm-contract/:contactId', component: CmContractComponent },
            { path: 'cm-contract/:contactId/:userId', component: CmContractComponent },
            { path: 'cm-contract-client/:contactId/:userId/:contractId', component: CmContractClientComponent },
            { path: 'cm-contract-mapping', component: CmContractMappingComponent },
            { path: 'cm-contract-log/:contractId', component: CmContractLogComponent },
            { path: 'cm-activity-log/:contractId', component: CmActivityLogComponent },
        ])
    ],
    providers: [DatePipe,EmailService, GlobalService, LocalService, MenuService, SearchContactService, UtilityService, NotificationService, ToastrService],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class AppModule { }
