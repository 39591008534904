export enum eFeatures {
  None = 0,
  RelevantTopics = 1,
  OnlineTutorials = 2,
  GettingStartedChecklist = 3,
  SubmitSupportTicket = 4,
  ReferralRewards = 5,
  NewCompany = 6,
  MyCompanies = 7,
  BulkCompanyActions = 8,
  NewContact = 9,
  ViewMyContacts = 10,
  ExcelUploadWizard = 11,
  BulkContactActions = 12,
  CreateContactMap = 13,
  RestoreContacts = 14,
  ManageDuplicates = 15,
  NewLead = 16,
  MyActiveLeads = 17,
  MyLeads = 18,
  BulkLeadActions = 19,
  ViewMailings = 20,
  CreateNewMailing = 21,
  ViewEmailBlastMaiings = 22,
  CreateNewEmailBlastMailing = 23,
  ViewTextBlastMailings = 24,
  CreateNewTextBlastMailing = 25,
  KeywordList = 26,
  AutomationProcesses = 27,
  CustomActionScreens = 29,
  WebForms = 30,
  EmailTemplates = 31,
  MailMergeTemplates = 33,
  TextMessageTemplates = 34,
  ImageBank = 35,
  ClickTrackings = 36,
  VoiceRecordings = 37,
  SlidecastPresentations = 38,
  AppointmentTypeSummary = 39,
  AppointmentbyTwoDimensions = 40,
  CoachCorner = 41,
  QualificationCallReport = 42,
  NoteTypeSummary = 43,
  CallActionButtonClickReport = 44,
  TextMessageLog = 45,
  EmailOpenRateReport = 46,
  SAMVoiceCallReport = 47,
  SlidecastAnalytics = 48,
  SkypeCallLog = 49,
  DistributionbyTwoDimesions = 50,
  DistribtionByManager = 51,
  DistributionbyClassification = 52,
  DistributionbyTwoDimension = 54,
  ReferralReport = 55,
  TagCloud = 56,
  ClickTracking = 57,
  BuzzScore = 58,
  ProjectedRevenuebyManager = 60,
  ProjectedRevenuebyClassification = 61,
  ProjectedRevenuebyMonth = 62,
  DistributionbyTwoDimensions = 63,
  FilterReport = 64,
  InvoiceReport = 65,
  AccountSetup = 66,
  UserSetup = 67,
  SecuritySettings = 68,
  CompanyModuleSettings = 69,
  ContactModuleSettings = 70,
  LeadModuleSettings = 71,
  AppointmentNoteMailingsettings = 72,
  TagSettings = 73,
  TextMessageSettings = 74,
  VoiceSettings = 75,
  SOSecurity = 76,
  SOBillingManager = 77,
  SOServicesContract = 78,
  SOAnnouncements = 79,
  SOFreeTrials = 80,
  SOServiceStatus = 81,
  SOAccountSetup = 82,
  RoleSetup = 83,
  Configuration = 84,
  TeamOfficeSetup = 85,
  CompanySetup = 86,
  WebFormManager = 87,
  SoRepSettings = 88,
  MyLinks = 89,
  EmailTemplate = 90,
  VideoBank = 93,
  PatentPlusAdmins = 94,
  PatentPlusReps = 95,
  ManageRoles = 96,
  OfficeSetup = 97,
  PauseLeads = 98,
  ApprovePauseRequest = 99,
  Reporting = 100,
  ConfigurationAdv = 101,
  ContractsAdministrator = 102,
  Contracts = 103,
  ContractsConfiguratio = 104,
  ViewContracts = 105,
  ReceivedContracts = 106,
  ResearchReview = 107,
  ResearchSubmission = 108,
  ResearchPrinters = 109,
  ResearchWriting = 110,
  ContractApproval = 111,
  ResearchTyping = 112,
  ClientServicesReview = 113,
  Portal = 114,
  AllContracts = 115,
  ContractList = 116,
  ContractsConfig = 117,
  Leads = 118
}

export enum eUserPermissions {
  Disabled = 0,
  Active = 1,
  Unknown = 9
}


export enum eCampaignTemplateOwnerType {
  Unknown = 0,
  Personal = 1,
  Contact = 2,
  Lead = 3,
  Proposal = 4,
  Project = 5
}


export enum eTempMMDocumentType {
  Unknown = 0,
  Personal = 1,
  Contact = 2,
  Lead = 3,
  Proposal = 4,
  Project = 5,
  LinkGroup = 6,
  Note = 7,
  Invoice = 8,
  Mailing = 11,
  EBlast = 12
}
