import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BCMCLPUser, CLPUser, TeamCodes } from '../../../models/clpuser.model';
import { GlobalService } from '../../../services/shared/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings, DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { CMClubService } from '../../../services/cm-club.service';
import { CMClubResponse, CMSite, CMSiteEmployees, CMSiteEmployeesListResponse, CMSiteEmployeesUdtRequest, CMSiteListResponse } from '../../../models/cm-club-service.model';
import { UserService } from '../../../services/user.service';
import { SimpleResponse, UserDD } from '../../../models/generic-response.model';
import { NotificationService } from '../../../services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { CMClub } from '../../../models/cm-general.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AfterViewInit } from '@angular/core';
import { merge, Observable, Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { AppConfigService } from '../../../services/shared/app-config.service';
import { ConfigDetails } from '../../../models/app-config.model';
declare var $: any;

@Component({
    selector: 'app-lm-employees',
    templateUrl: './lm-employees.component.html',
    styleUrl: './lm-employees.component.css'
})
export class LmEmployeesComponent implements AfterViewInit, OnDestroy {
    @ViewChild("dropdownlist") public dropdownlist: DropDownListComponent;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<CMSiteEmployees>;
    encryptedUser: string = '';
    user: BCMCLPUser;
    addEmployeeForm: FormGroup
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    sites: CMSite[];
    class1Code: number;
    club: CMClub;
    clpUsersDD: UserDD[];
    requestEmployee: CMSiteEmployeesUdtRequest[];
    siteEmployeesList: CMSiteEmployees[];
    displayedColumns: string[] = ['userName', 'phoneNumber', 'email', 'roleName', 'action'];
    siteEmployee: CMSiteEmployees = <CMSiteEmployees>{}
    submitted: boolean;
    curTeam: TeamCodes = <TeamCodes>{};
    curSite: CMSite = <CMSite>{};
    clubConfig: CMClub;
    showSpinner: boolean;
    isEdit: boolean;
    curEmployee: CMSiteEmployees = <CMSiteEmployees>{};
    allRolesList: any = [];
    copyAllRoleList = [];
    comnExist: boolean = false
    filter: string;
    selectedRowIndex: number = -1;
    curSiteSub: Subscription;
    clpCompanyId: number;
    isAI: boolean = false;
    showOptionalColumn: boolean = false;
    isShowOp: boolean = false;
    selectedEmp: number = 0;

    constructor(private localService: LocalService,
        public _localService: LocalService,
        private _globalService: GlobalService,
        private fb: FormBuilder,
        private _router: Router,
        private clubService: CMClubService,
        private _utilityService: UtilityService,
        private notifyService: NotificationService,
        private userService: UserService,
        private _appconfigService: AppConfigService
    ) {
        this.localService.setShowMenu(true)
        this.localService.setIsCmManager(false);
        this.localService.setRouteName("sm-employees")

    }


    selectRow(row: any): void {
        if (row) {
            this.selectedRowIndex = row.sn;
        }
    }

    public addNew(): void {
        this.copyAllRoleList.push(this.filter);
        this.handleFilter(this.filter);
    }

    public handleFilter(value) {
        this.filter = value;
        let copyRoles = [...this.copyAllRoleList]
        this.allRolesList = copyRoles.filter(
            (s) => s.toLowerCase().trim().indexOf(value.toLowerCase().trim()) !== -1);
    }

    public onClose(event: any) {
        event.preventDefault();
        if (this.filter && this.filter.trim()?.length > 0) {
            if (this.copyAllRoleList?.length > 0) {
                let index = this.copyAllRoleList.indexOf(this.filter)
                if (index == -1) {
                    this.copyAllRoleList.push(this.filter);
                    this.handleFilter(this.filter)
                }
            } else {
                this.copyAllRoleList.push(this.filter);
                this.handleFilter(this.filter)
            }
        }
        setTimeout(() => {
            if (!this.dropdownlist.wrapper.nativeElement.contains(document.activeElement)) {
                this.dropdownlist.toggle(false);
            }
        });
    }



    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
        this.addEmployeeForm = this.prepareAddEmployeeForm()
    }

    ngAfterViewInit() {
        this.encryptedUser = localStorage.getItem("token");
        if (this.encryptedUser) {
            this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadData()
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }
    }


    async loadData() {
        this.curTeam = JSON.parse(localStorage.getItem("curTeam") || '{}')
        await this.CMClub_Get()
        this.cLPUserGetListByTeamCode(this.clpCompanyId, this.curTeam?.teamCode)
        this.curSiteSub = this.clubService.curSite.subscribe(async (value: CMSite) => {
            this.isShowOp = false;
            this.curSite = await value
            if (this.curSite == null) {
                this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')
            }
            this.siteEmployeesSearch()
            this.siteSearch();
        })
    }
    prepareAddEmployeeForm() {
        return this.fb.group({
            user: [0, [Validators.required]],
            role: ["--All--", [Validators.required]]
        })
    }

    private async authenticateR() {

        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async siteEmployeesSearch() {
        if (this.curSite?.siteID > 0) {
            let clubId = this.clubConfig?.clubId > 0 ? this.clubConfig?.clubId : 0
            await this.clubService.siteEmployeesSearch(this.encryptedUser, this.clpCompanyId, this.curSite?.siteID, clubId, 0 , 0, "", this.isAI).
                then(async (result: CMSiteEmployeesListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.siteEmployeesList = result.listSiteEmployees;
                        this.allRolesList = ["--All--", 'Signing Officer', 'Contract Auditor']
                        this.siteEmployeesList.forEach((item, i) => {
                            item['sn'] = i + 1
                            if (this.allRolesList.indexOf(item?.roleName) == -1 && item.roleName.toLowerCase() != "signing officer")
                                this.allRolesList.push(item?.roleName);
                        })
                        this.copyAllRoleList = UtilityService.clone(this.allRolesList)
                        this.dataSource = new MatTableDataSource(this.siteEmployeesList);
                        this.dataSource.sort = this.sort;
                        this.dataSource.paginator = this.paginator;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }


    async CMClub_Get() {
        if (this.curTeam?.teamCode > 0) {
            this.showSpinner = true
            await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, this.curTeam?.teamCode, this.isAI).
                then(async (result: CMClubResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.clubConfig = response?.clubConfig
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async cLPUserGetListByTeamCode(clpCompanyId, teamCode) {
        this.showSpinner = true
        await this.userService.cLPUserGetListByTeamCode(this.encryptedUser, clpCompanyId, teamCode,this.isAI).
            then(async (result: UserDD[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.clpUsersDD = response
                    this.clpUsersDD.unshift({
                        value: 0,
                        text: "--All--"
                    })
                    this.addEmployeeForm.controls.user.patchValue(this.clpUsersDD[0]?.value)
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("contact-task.getLoadUsers", err.message, null,
                    'clpCompanyId: ' + this.clpCompanyId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async siteEmployeesBulkInsert() {
        let role = this.addEmployeeForm.controls['role'].value
        let user = this.addEmployeeForm.controls['user'].value
        this.comnExist = false
        if (this.siteEmployeesList?.length > 0) {
            this.siteEmployeesList.forEach((item) => {
                if (item?.roleName.toLowerCase() == role.toLowerCase() && item?.userID == user) {
                    this.notifyService.showError(`${item?.userName} with role ${role} is already exists!`, "Duplicate", 3000)
                    this.comnExist = true
                }
            })
        }

        if (role.trim() == '') {
            this.notifyService.showError(`Role is required`, "Error", 3000)
            return
        }
        if (user == 0) {
            this.notifyService.showError(`Select user.`, "Error", 3000)
            return
        }
        if (this.addEmployeeForm.valid && !this.comnExist) {
            this.submitted = true
            this.siteEmployee.iD = this.isEdit ? this.siteEmployee?.iD > 0 ? this.siteEmployee?.iD : 0 : 0;
            this.siteEmployee.roleName = this.addEmployeeForm.controls['role'].value
            this.siteEmployee.userID = this.addEmployeeForm.controls['user'].value
            this.siteEmployee.siteID = this.curSite?.siteID
            this.siteEmployee.clpcompanyId = this.clpCompanyId
            this.siteEmployee.clubID = this.clubConfig?.clubId
            this.siteEmployee.class1Code = this.class1Code
            this.submitted = true
            await this.clubService.CMSiteEmployees_Update(this.encryptedUser, this.siteEmployee, this.isAI).
                then(async (result: SimpleResponse) => {
                    if (result.messageBool) {
                        let response = UtilityService.clone(result);                       
                            this.isEdit = false
                            this.siteEmployeesSearch();
                            this.notifyService?.showSuccess("Employees updated.", "Success", 3000);
                            this.addEmployeeForm.reset();
                            this.addEmployeeForm = this.prepareAddEmployeeForm();
                    } else if (result.messageInt == -1) {                        
                        this.notifyService.showError(`${this.clpUsersDD?.find(x => x.value == user)?.text} with role ${role} is already exists!`, "Duplicate", 3000);
                    }  
                    this.submitted = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.submitted = false
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    onEdit(employee) {
        this.siteEmployee = employee
        this.isEdit = true;
        this.selectedEmp = this.siteEmployee.iD
        this.addEmployeeForm.controls['role'].patchValue(employee?.roleName)
        if (this.clpUsersDD?.length > 0) {
            let user = this.clpUsersDD.filter((user) => user?.value == employee?.userID)[0]
            if (user && user?.value > 0) {
                this.addEmployeeForm.controls['user'].patchValue(employee?.userID)
            }
            else {
                this.addEmployeeForm.controls['user'].patchValue(0)
            }
        }

    }

    openDltConfirm(element) {
        this.curEmployee = element
    }

    async onDeleteEmp() {
        if (this.curEmployee && this.curEmployee?.iD > 0) {
            this.showSpinner = true
            await this.clubService.siteEmployeesRemove(this.encryptedUser, this.curEmployee?.iD, 'From Site' , this.isAI)
                .then(async (result: CMClubResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.isEdit = false;
                        this.selectedRowIndex = -1;
                        this.notifyService.showSuccess(`${this.curEmployee?.userName} with role ${this.curEmployee?.roleName} deleted.`, "Success", 3000);
                        this.addEmployeeForm.reset();
                        this.siteEmployeesSearch();
                        this.addEmployeeForm.reset();
                        this.addEmployeeForm = this.prepareAddEmployeeForm();
                        $('#deleteRoleModal').modal('hide');
                    }
                    this.showSpinner = false

                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this.notifyService.showError("Some error occured", "Error", 3000)
        }
    }

    ngOnDestroy() {
        if (this.curSiteSub) {
            this.curSiteSub.unsubscribe()
        }
    }


    async clubSiteLevel(toSiteId: number) {
        if (toSiteId == 0) {
            this.isShowOp = false
        }
        else {
            this.showSpinner = true;
            await this.clubService.employeesClone_Site(this.encryptedUser, this.user?.cLPCompanyID, this.clubConfig?.clubId, this.curSite?.siteID, toSiteId, this.isAI)
                .then(async (result: SimpleResponse) => {
                    if (result) {
                        this.notifyService.showSuccess(`Employee has been Cloned Succesfully`, "Success", 3000)
                        this.siteEmployeesSearch()
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async siteSearch() {
        if (this.clubConfig?.clubId > 0) {
            await this.clubService.siteSearch(this.encryptedUser, 0, this.clubConfig?.clubId, 0, 0, this.isAI).
                then(async (result: CMSiteListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.sites = response?.sites
                        this.sites = this.sites.filter(item => item.siteID !== this.curSite?.siteID);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    showCloneDD() {
        this.isShowOp = true;
    }

}
