import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BCMCLPUser } from '../../models/clpuser.model';
import { CMAPILog, CMAPILogListResponse, CMContractLog, CMContractLogListResponse } from '../../models/cm-contract';
import { CMContractService } from '../../services/cm-contract.service';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';

@Component({
    selector: 'app-cm-activity-log',
    templateUrl: './cm-activity-log.component.html',
    styleUrl: './cm-activity-log.component.css'
})
export class CmActivityLogComponent {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<CMAPILog>;
    displayedColumns: string[] = ['vendorName', 'cMContractID', 'contactName', 'endPoint', 'rawJSON', 'rawResponse', 'diagnostics', 'dtCreated'];
    isAI: boolean;
    encryptedUser: string;
    user: BCMCLPUser;
    contractId: number = 0;
    apiLogList: CMAPILog[];
    pageSize: number = 10

    constructor(private localService: LocalService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _contractService: CMContractService,
        private titleService: Title,

    ) {
        this.titleService.setTitle('Activity Log');
        this.localService.setShowMenu(true);
        this.localService.setIsContract(true);
        this.localService.setIsCmManager(false);
        this.loadPageSize()
    }

    ngOnInit() {

        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false;
        this._route.queryParamMap.subscribe(params => {
            this.encryptedUser = params.get('r');
            this.contractId = +this._route.snapshot.paramMap.get('contractId');
            if (!this.encryptedUser?.includes(":")) {
                this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
            }
            if (this.encryptedUser) {
                this.authenticateR().then(() => {
                    if (this.user) {
                        localStorage.setItem("token", this.encryptedUser);
                        this.cMContractLog_GetList()
                    }
                    else {
                        this._router.navigate(['/unauthorized']);
                    }
                });
            }
            else {
                this._router.navigate(['/unauthorized']);
            }
        });

        this.filterDataSource();
    }

    private async authenticateR() {
        await this.localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadPageSize() {
        const storedPageSize = localStorage.getItem('pageSize');
        if (storedPageSize) {
            this.pageSize = +storedPageSize;
        }
    }

    onPageChange(event: any) {
        localStorage.setItem('pageSize', event.pageSize);
    }


    async cMContractLog_GetList() {
        await this._contractService.CMAPILog_GetList(this.encryptedUser, this.contractId, this.isAI)
            .then(async (result: CMAPILogListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.apiLogList = response?.apiLogList;
                    this.dataSource = new MatTableDataSource(this.apiLogList);
                    setTimeout(() => {
                        this.dataSource.paginator = this.paginator;
                    }, 1000)
                    this.dataSource.sort = this.sort;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.Log", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    filterDataSource() {
        if (this.dataSource) {
            this.dataSource.filterPredicate = (data, filter: string) => {
                const dataStr = Object.values(data).join(' ').toLowerCase();
                return dataStr.includes(filter);
            };
        }

    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim().toLowerCase();
        if (this.dataSource) {
            this.dataSource.filter = filterValue;
        }
    }

    getFormattedDate(date: string) {
        if (date) {
            let d = new Date(date);
            let curr_date = d.getDate();
            let curr_month = d.getMonth() + 1; //Months are zero based
            let curr_year = d.getFullYear();
            let curr_hour = d.getHours();
            let curr_minute = String(d.getMinutes()).padStart(2, '0');
            let curr_second = String(d.getSeconds()).padStart(2, '0');

            const ampm = curr_hour >= 12 ? 'PM' : 'AM';
            const formattedTime = `${curr_hour % 12 || 12}:${curr_minute}:${curr_second} ${ampm}`;

            return `${curr_month}/${curr_date}/${curr_year} ${formattedTime}`;
        }
        else
            return '';
    }
}
