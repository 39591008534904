<div class="main-wrapper-section">
    <div class="common-layout-section">
        <div class="common-header">
            <h3>Links </h3>
            <select class="form-control margin-left15" *ngIf="isShowClone" (change)="onChangeCloneOpt($event)" style="width:auto">
                <option selected="selected" value="">-Select Action-</option>
                <option value="-1" *ngFor="let item of cloneOpts" [value]="item?.value">{{item?.display}}</option>
            </select>
            <button class="btn primary-btn margin-left15" *ngIf="!isShowClone && cloneOpts?.length > 1" (click)="showCloneDD()">Clone</button>
        </div>
    </div>
    <div class="card-section">
        <form [formGroup]="linksForm">
            <div class="card-panel">
                <div class="flex-form">
                    <div class="form-group">
                        <label>Schedule Site Visit URL</label>
                        <input class="form-control" formControlName="visitURL" maxlength="50" />
                    </div>
                    <div class="form-group">
                        <label>Schedule Open House URL</label>
                        <input class="form-control" formControlName="houseURL" maxlength="50" />
                    </div>
                    <div class="form-group">
                        <label>Vessels URL</label>
                        <input class="form-control" formControlName="vesselsURL" maxlength="50" />
                    </div>
                </div>
                <div class="flex-form">
                    <div class="form-group">
                        <label>
                            Kiosk URL
                        </label>
                        <input class="form-control" formControlName="kioskURL" maxlength="50" />
                    </div>
                    <div class="form-group">
                        <label>{{clubSiteConfig?.lnkCustom1URL ? clubSiteConfig?.lnkCustom1URL : "Custom 1 URL"}}</label>
                        <input class="form-control" formControlName="Custom1" maxlength="50" />
                    </div>
                    <div class="form-group">
                        <label>{{clubSiteConfig?.lnkCustom2URL ? clubSiteConfig?.lnkCustom2URL : "Custom 2 URL"}}</label>
                        <input class="form-control" formControlName="Custom2" maxlength="50" />
                    </div>
                </div>
                <div class="flex-form">
                    <div class="form-group">
                        <label>{{clubSiteConfig?.lnkCustom3URL ? clubSiteConfig?.lnkCustom3URL : "Custom 3 URL"}}</label>
                        <input class="form-control" formControlName="Custom3" maxlength="50" />
                    </div>
                    <div class="form-group">
                        <label>{{clubSiteConfig?.lnkCustom4URL ? clubSiteConfig?.lnkCustom4URL : "Custom 4 URL"}}</label>
                        <input class="form-control" formControlName="Custom4" maxlength="50" />
                    </div>
                    <div class="form-group">
                        <label>{{clubSiteConfig?.lnkCustom5URL ? clubSiteConfig?.lnkCustom5URL : "Custom 5 URL"}}</label>
                        <input class="form-control" formControlName="Custom5" maxlength="50" />
                    </div>
                </div>
                <div class="button-group">
                    <div class="form-group">
                        <button class="primary-btn" (click)="linksSaved()">Save<i *ngIf="submitted" class="fas fa-spinner fa-spin"></i></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!--<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>-->
