<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Activity Log</h3>
    </div>
    <div class="card-section">
        <div class="card-panel">
            <div class="table-panel">
                <div class="">
                    <div class="text-right">
                        <div class="button-group" style="border:none;">
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                        </div>
                    </div>
                </div>
                <div class="table-body activity-log">
                    <table mat-table [dataSource]="dataSource" class="contracts-table" matSort>

                        <ng-container matColumnDef="vendorName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor</th>
                            <td mat-cell *matCellDef="let element"><div class="members-json">{{element.vendorName }}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="cMContractID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract</th>
                            <td mat-cell *matCellDef="let element"><div class="members-json">{{element.cMContractID }}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="contactName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
                            <td mat-cell *matCellDef="let element"><div class="members-json">{{element.contactName }}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="endPoint">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>EndPoint</th>
                            <td mat-cell *matCellDef="let element"><div class="members-json" [innerHTML]="element.endPoint | safeHtml">{{element.endPoint }}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="rawJSON">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Request</th>
                            <td mat-cell *matCellDef="let element"><div class="members-json" [innerHTML]="element.rawJSON | safeHtml"></div></td>
                        </ng-container>
                        <ng-container matColumnDef="rawResponse">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Response</th>
                            <td mat-cell *matCellDef="let element"><div class="members-json" [innerHTML]="element.rawResponse | safeHtml"></div></td>
                        </ng-container>
                        <ng-container matColumnDef="diagnostics">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Diagnostics</th>
                            <td mat-cell *matCellDef="let element"><div class="members-json" [innerHTML]="element.diagnostics | safeHtml"></div></td>
                        </ng-container>
                        <ng-container matColumnDef="dtCreated">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
                            <td mat-cell *matCellDef="let element"><div class="members-json">{{getFormattedDate(element.dtCreated)}}</div></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="apiLogList?.length > 0"
                                   [pageSize]="pageSize"
                                   (page)="onPageChange($event)" [length]="apiLogList?.length" [pageSizeOptions]="[5, 10, 15, 50]"></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>

