<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Contracts Log</h3>
    </div>
    <div class="card-section">
        <div class="card-panel">
            <div class="table-panel">
                <div class="">
                    <div class="text-right">
                        <div class="button-group" style="border:none;">
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                        </div>
                    </div>
                </div>
                <div class="table-body contract-log">
                    <table mat-table [dataSource]="dataSource" class="contracts-table" matSort>
                        <ng-container matColumnDef="contactName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
                            <td mat-cell *matCellDef="let element"> <div class="members-name">{{element?.contactName}}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="userName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
                            <td mat-cell *matCellDef="let element"> <div class="members-name" [innerHTML]="element?.userName | safeHtml"></div></td>
                        </ng-container>
                        <ng-container matColumnDef="isFaceToFace">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Face To Face</th>
                            <td mat-cell *matCellDef="let element"><div class="members-product">{{element.isFaceToFace}}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="notes">
                            <th mat-header-cell *matHeaderCellDef>Notes</th>
                            <td mat-cell *matCellDef="let element"><div class="members-site" [innerHTML]="element.notes | safeHtml"></div></td>
                        </ng-container>
                        <ng-container matColumnDef="sessionID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Client Session</th>
                            <td mat-cell *matCellDef="let element"><div class="members-date">{{element.sessionID ? element.sessionID : ''}}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="dtCreated">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
                            <td mat-cell *matCellDef="let element"><div class="members-date">{{getFormattedDate(element.dtCreated)}}</div></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="contractLogList?.length > 0"
                                   [pageSize]="pageSize"
                                   (page)="onPageChange($event)" [length]="contractLogList?.length" [pageSizeOptions]="[5, 10, 15, 50]"></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>

