import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CMSiteEmployeesAccept } from '../models/cm-club-service.model';
import { CMContract, ContractResponse, CMContractSetting, CMContractSettingResponse, Contact, CMContractNavListResponse, CMNavigationResponse, eCMNavigation_Update_Type, ContractAcceptation, CMContractListResponse, CMContractFeeListResponse, CMMemberFeeListResponse, PaymentReviewListResponse, ContractTextMessage, ContractPdfConvertContainer, CMContractLstResponse, CMContractListRequest, CMContractLog, CMSession, CMContractLogListResponse, CMAPILogListResponse } from '../models/cm-contract';
import { SimpleResponse } from '../models/generic-response.model';
import { delayedRetryHttp } from './shared/delayed-retry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class CMContractService {

    private baseUrl: string;
    private api: string = 'api/CMContract';


    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async cmContractGet(encryptedUser: string, clpCompanyId: number, clubId: number, siteId: number, contractId: number, isAI: boolean): Promise<ContractResponse | void> {
        const a = await this.httpClient
            .get<ContractResponse>(`${this.baseUrl}/CMContract_Get/${clpCompanyId}?clubID=${clubId}&siteID=${siteId}&contractId=${contractId}&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clubId - " + clubId + "," + "siteId - " + siteId, encryptedUser, "CMContractService", "cmContractGet"); });
        return a;
    }

    async cmContractGetById(encryptedUser: string, clpuserID: number, contactID: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMMember_GetContractID/${clpuserID}/${contactID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpuserID - " + clpuserID + "," + "contactID - " + contactID, encryptedUser, "CMContractService", "cmContractGet"); });
        return a;
    }

    async CMContract_Update(encryptedUser: string, contract: CMContract, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMContract_Update?isAI=${isAI}`, contract, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cmContractUpdate"); });
        return a;
    }

    async CMSiteEmployees_SignAccepted(encryptedUser: string, empAccept: CMSiteEmployeesAccept, isCleared: boolean, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMSiteEmployees_SignAccepted?isCleared=${isCleared}&isAI=${isAI}`, empAccept, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "CMSiteEmployees_SignAccepted"); });
        return a;
    }

    async CMContract_Delete(encryptedUser: string, contractId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMContract_Delete?cmContractId=${contractId}&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "CMContract_Delete"); });
        return a;
    }


    async cmMemberDelete(encryptedUser: string, memberId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMMember_Delete/${memberId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cmMemberDelete"); });
        return a;
    }


    async LoadContractMemberDisplay(encryptedUser: string, contactId: number, isAI: boolean): Promise<any | void> {
        const a = await this.httpClient
            .get<any>(`${this.baseUrl}/LoadContractMemberDisplay/${contactId}?&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactId, encryptedUser, "CMContractService", "getContactDetails"); });
        return a;
    }

    async cmSiteContractSetting_Get(encryptedUser: string, siteId: number, isAI: boolean): Promise<CMContractSettingResponse | void> {
        const a = await this.httpClient
            .get<CMContractSettingResponse>(`${this.baseUrl}/CMSiteContractSetting_Get/${siteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "siteId - " + siteId, encryptedUser, "CMContractService", "cmSiteContractSetting_Get"); });
        return a;
    }

    async cmSiteContractSetting_Update(encryptedUser: string, setting: CMContractSetting, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMSiteContractSetting_Update?isAI=${isAI}`, setting, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cmSiteContractSetting_Update"); });
        return a;
    }

    async CMContact_Create(encryptedUser: string, contact: Contact, siteSelected: number = 0, CMContractActive: boolean = false, isAI: boolean=false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMContact_Create?isAI=${isAI}`, contact, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cmSiteMember_Update"); });
        return a;
    }

    async cMContractNavCreate(encryptedUser: string, contractId: number, contactID: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMContractNav_Create/${contractId}/${contactID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cMContractNavCreate"); });
        return a;
    }

    async navigation_Create(encryptedUser: string, contractId: number, contactID: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMNavigation_Create/${contractId}/${contactID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cMContractNavCreate"); });
        return a;
    }

    async navigationGet(encryptedUser: string, contractId: number, contactID: number, isAI = false): Promise<CMNavigationResponse | void> {
        const a = await this.httpClient
            .get<CMNavigationResponse>(`${this.baseUrl}/CMNavigation_Get/${contractId}/${contactID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "navigationGet"); });
        return a;
    }

    async navigationUpdate(encryptedUser: string, cMNavigationID: number, update_Type: eCMNavigation_Update_Type, value: boolean, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMNavigation_Update/${cMNavigationID}/${update_Type}/${value}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "navigationUpdate"); });
        return a;
    }

    async productGet(encryptedUser: string, clpcompanyID: number, contractId: number, contactID: number, cmroductDocId: number, cmContractNavID: number, isAI = false): Promise<CMContractNavListResponse | void> {
        const a = await this.httpClient
            .get<CMContractNavListResponse>(`${this.baseUrl}/CMContractNav_Get/${clpcompanyID}/${contractId}/${contactID}?cmroductDocId=${cmroductDocId}&cmContractNavID=${cmContractNavID}&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "productGet"); });
        return a;
    }

    async cmContractNavisAccepted(encryptedUser: string, _cmAccept: ContractAcceptation, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMContractNav_isAccepted?isAI=${isAI}`, _cmAccept, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cmContractNavisAccepted"); });
        return a;
    }

    async sendMemberEmail(encryptedUser: string, toContactID: number, contractID: number, clpCompanyID: number, cLPUserId: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SendMemberEmail/${toContactID}/${contractID}/${clpCompanyID}/${cLPUserId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "sendMemberEmail"); });
        return a;
    }

    async sendMemberText(encryptedUser: string, toContactID: number, contractID: number, clpCompanyID: number, clpUserID: number, txtMsg: string, mediaURL: string, sendEmail: boolean, isAI = false): Promise<SimpleResponse | void> {
        let t: ContractTextMessage = {
            toContactID: toContactID,
            contractID: contractID,
            clpCompanyID: clpCompanyID,
            clpUserID: clpUserID,
            txtMsg: txtMsg,
            mediaURL: mediaURL,
            sendEmail: sendEmail
        }
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/SendMemberText?isAI=${isAI}`, t, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "sendMemberText"); });
        return a;
    }

    async unixTimestamp(contractId: number, clpCompanyID: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/UnixTimestamp/${contractId}/${clpCompanyID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "clpCompanyID - " + { clpCompanyID } + "contractId -" + contractId, "CMContractService", "unixTimestamp"); });
        return a;
    }

    async cMContractFee_Create(encryptedUser: string, cmContractID: number, feeBase: number, feeTax: number, sOrder: number, cmProductFeeID: number, cmFeeName: string, isAI: boolean): Promise<CMContractListResponse | void> {
        const a = await this.httpClient
            .get<CMContractListResponse>(`${this.baseUrl}/CMContractFee_Create/${cmContractID}/${feeBase}/${feeTax}/${sOrder}/${cmProductFeeID}/${cmFeeName}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "cmContractID - " + { cmContractID }, "CMContractService", "cMContractFee_Create"); });
        return a;
    }

    async cMContractFee_Get(encryptedUser: string, cmContractID: number, isAI: boolean): Promise<CMContractFeeListResponse | void> {
        const a = await this.httpClient
            .get<CMContractFeeListResponse>(`${this.baseUrl}/CMContractFee_Get/${cmContractID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "cmContractID - " + { cmContractID }, "CMContractService", "cMContractFee_Create"); });
        return a;
    }

    async getMemberFee(encryptedUser: string, contractId: number, cmMemberID: number, isAI = false): Promise<CMMemberFeeListResponse | void> {
        const a = await this.httpClient
            .get<CMMemberFeeListResponse>(`${this.baseUrl}/CMMemberFee_Get/${contractId}/${cmMemberID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "getMemberFee"); });
        return a;
    }

    async GetPaymentReviews(encryptedUser: string, clpcompanyID: number, siteID: number, contractId: number, memberID: number, isAI = false): Promise<PaymentReviewListResponse | void> {
        const a = await this.httpClient
            .get<PaymentReviewListResponse>(`${this.baseUrl}/GetPaymentReviews/${clpcompanyID}/${siteID}/${contractId}/${memberID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "GetPaymentReviews"); });
        return a;
    }

    async cMMember_Exists(encryptedUser: string, contactId: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMMember_Exists/${contactId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cMMember_Exists"); });
        return a;
    }

    async GetContractPDFAsync(encryptedUser: string, fileName: string, fileLength: number, isAI = false): Promise<any | void> {
        const a = await this.httpClient
            .get<any>(`${this.baseUrl}/GetContractPDF/${fileName}/${fileLength}?isAI=${isAI}`, {
                'responseType': 'arraybuffer' as 'json',
                headers: new HttpHeaders({
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "GetContractPDFAsync"); });
        return a;
    }

    async getTextMessagePH(encryptedUser: string, userId: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/GetTextMessagePH/${userId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "getTextMessagePH"); });
        return a;
    }
    async ProcessorContractPDFConvert_Add(encryptedUser: string, companyId: number, contractId: number, contactId: number, siteId: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ProcessorContractPDFConvert_Add/${companyId}/${contractId}/${contactId}/${siteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "ProcessorContractPDFConvert_Add"); });
        return a;
    }

    async ProcessorContractPDFConvert_Get(encryptedUser: string, companyId: number, contractId: number, contactId: number, siteId: number, isAllMember: boolean, isAI = false): Promise<ContractPdfConvertContainer | void> {
        const a = await this.httpClient
            .get<ContractPdfConvertContainer>(`${this.baseUrl}/ProcessorContractPDFConvert_Get/${companyId}/${contractId}/${contactId}/${siteId}/${isAllMember}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "ProcessorContractPDFConvert_Get"); });
        return a;
    }

    async ProcessorContractPDFConvert_Archive(encryptedUser: string, companyId: number, contractId: number, contactId: number, siteId: number, isAllMember: boolean, isAI = false): Promise<ContractPdfConvertContainer | void> {
        const a = await this.httpClient
            .get<ContractPdfConvertContainer>(`${this.baseUrl}/ProcessorContractPDFConvert_Archive/${companyId}/${contractId}/${contactId}/${siteId}/${isAllMember}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "ProcessorContractPDFConvert_Archive"); });
        return a;
    }

    async ProcessorDLContractPDFConvert_Get(encryptedUser: string, companyId: number, contractId: number, contactId: number, siteId: number, isAI = false): Promise<ContractPdfConvertContainer | void> {
        const a = await this.httpClient
            .get<ContractPdfConvertContainer>(`${this.baseUrl}/ProcessorDLContractPDFConvert_Get/${companyId}/${contractId}/${contactId}/${siteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "ProcessorDLContractPDFConvert_Get"); });
        return a;
    }

    async cmContractStatusUpdate(encryptedUser: string, contractId: number,clpCompanyId : number, statusID: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMContract_Status_Update/${contractId}/${clpCompanyId}/${statusID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cmContractStatusUpdate"); });
        return a;
    }

    async cmMemberStatusUpdate(encryptedUser: string, clpCompanyId: number, memberId: number, statusId: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMMember_Status_Update/${clpCompanyId}/${memberId}/${statusId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cmContractStatusUpdate"); });
        return a;
    }

    async dtSignedMembers_Update(encryptedUser: string, contractId: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMContract_dtSignedMembers_Update/${contractId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cmContractStatusUpdate"); });
        return a;
    }

    async cMContractGetList(encryptedUser: string, clpuserId: number, cMContractListRequest: CMContractListRequest, isAI = false): Promise<CMContractLstResponse | void> {
        const a = await this.httpClient
            .post<CMContractLstResponse>(`${this.baseUrl}/CMContract_GetList/${clpuserId}?isAI=${isAI}`, cMContractListRequest, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "CMContract_GetList"); });
        return a;
    }

    async CMContractLog_Create(encryptedUser: string, log: CMContractLog, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMContractLog_Create?isAI=${isAI}`, log, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "CMContractLog_Create"); });
        return a;
    }

    async CMContract_Update_SessionId(encryptedUser: string, session: CMSession, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMContract_Update_SessionId?isAI=${isAI}`, session, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "CMContract_Update_SessionId"); });
        return a;
    }

    async CMContract_Get_SessionId(encryptedUser: string, cmContractId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMContract_Get_SessionId/${cmContractId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cmContractId - " + cmContractId, encryptedUser, "CMContractService", "CMContract_Get_SessionId"); });
        return a;
    }

    async cMContractLog_GetList(encryptedUser: string, cmContractId: number, isAI: boolean): Promise<CMContractLogListResponse | void> {
        const a = await this.httpClient
            .get<CMContractLogListResponse>(`${this.baseUrl}/CMContractLog_GetList/${cmContractId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cmContractId - " + cmContractId, encryptedUser, "CMContractService", "cMContractLog_GetList"); });
        return a;
    }

    async CMAPILog_GetList(encryptedUser: string, cmContractId: number, isAI: boolean): Promise<CMAPILogListResponse | void> {
        const a = await this.httpClient
            .get<CMAPILogListResponse>(`${this.baseUrl}/CMAPILog_GetList/${cmContractId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cmContractId - " + cmContractId, encryptedUser, "CMContractService", "CMAPILog_GetList"); });
        return a;
    }

    async CM_MemberDLFileUpload(encryptedUser: string, formData: any, contractId: number, clpCompanyId: number, cmMemberId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CM_MemberDLFileUpload?contractId=${contractId}&clpCompanyId=${clpCompanyId}&cmMemberId=${cmMemberId}&isAI=${isAI}`, formData, {
                headers: new HttpHeaders({
                    //'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "CM_MemberDLFileUpload"); });
        return a;
    }

    async CMContract_CheckMemberDL(encryptedUser: string, contractId: number, clpCompanyId: number, contactId: number, siteId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMContract_CheckMemberDL/${contractId}/${clpCompanyId}/${contactId}/${siteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    //'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "CMContract_CheckMemberDL"); });
        return a;
    }

    async ContractOpenAutomation(encryptedUser: string, clpcompanyId: number, contractID: number, contactID: number, is_client: boolean, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ContractOpenAutomation/${clpcompanyId}/${contractID}/${contactID}/${is_client}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "CMContract_CheckMemberDL"); });
        return a;
    }

    async cMSiteEmployees_Signature_Get(encryptedUser: string, clpcompanyId: number, cmEmployeeId: number, clpUserId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMSiteEmployees_Signature_Get/${clpcompanyId}/${cmEmployeeId}/${clpUserId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cMSiteEmployees_Signature_Get"); });
        return a;
    }

    async cMSiteEmployees_Signature_Delete(encryptedUser: string, clpcompanyId: number, cmEmployeeId: number, clpUserId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMSiteEmployees_Signature_Delete/${clpcompanyId}/${cmEmployeeId}/${clpUserId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "CMContractService", "cMSiteEmployees_Signature_Delete"); });
        return a;
    }

}
