<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Contract Settings</h3>
    </div>
    <div class="card-section">
        <!--<form [formGroup]="siteForm">-->
        <div class="card-panel margin-top20">
            <h6>Accepted Payment Methods</h6>
            <hr />
            <form [formGroup]="configForm" (ngSubmit)="saveContractSetting()">
                <div class="payment-grid">
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Non-Recurring Fees</th>
                                <th>Recurring Fees</th>
                                <th>Payment Templates</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>Credit Card</b></td>
                                <td>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="isEntryCC" formControlName="isEntryCC">
                                        <label class="custom-control-label" for="isEntryCC"></label>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="isMonthlyCC" formControlName="isMonthlyCC">
                                        <label class="custom-control-label" for="isMonthlyCC"></label>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-control">
                                        <kendo-combobox class="form-control" [data]="mailMergeTempList"
                                                        [valuePrimitive]="true"
                                                        textField="text"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        formControlName="CCAuthMMID"
                                                        valueField="id">
                                        </kendo-combobox>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><b>ACH</b></td>
                                <td>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="isEntryACH" formControlName="isEntryACH">
                                        <label class="custom-control-label" for="isEntryACH"></label>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="isMonthlyACH" formControlName="isMonthlyACH">
                                        <label class="custom-control-label" for="isMonthlyACH"></label>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-control">
                                        <kendo-combobox class="form-control" [data]="mailMergeTempList"
                                                        [valuePrimitive]="true"
                                                        textField="text"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        formControlName="ACHAuthMMID"
                                                        valueField="id">
                                        </kendo-combobox>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Physical Check</b></td>
                                <td>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="isEntryCheck" formControlName="isEntryCheck">
                                        <label class="custom-control-label" for="isEntryCheck"></label>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="isMonthlyCheck" formControlName="isMonthlyCheck">
                                        <label class="custom-control-label" for="isMonthlyCheck"></label>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-control">
                                        <kendo-combobox class="form-control" [data]="mailMergeTempList"
                                                        [valuePrimitive]="true"
                                                        textField="text"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        formControlName="CheckAuthMMID"
                                                        valueField="id">
                                        </kendo-combobox>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Cash</b></td>
                                <td>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="isEntryCash" formControlName="isEntryCash">
                                        <label class="custom-control-label" for="isEntryCash"></label>
                                    </div>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <div class="custom-control">
                                        <kendo-combobox class="form-control" [data]="mailMergeTempList"
                                                        [valuePrimitive]="true"
                                                        textField="text"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        formControlName="CashAuthMMID"
                                                        valueField="id">
                                        </kendo-combobox>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-12">
                        <h6>Contract Email to Client Template</h6>
                        <hr />
                        <div class="row margin-top10">
                            <div class="col-lg-12 col-sm-12">
                                <div>
                                    <kendo-combobox class="form-control" [data]="emailTempList"
                                                    [valuePrimitive]="true"
                                                    textField="text"
                                                    [kendoDropDownFilter]="filterSettings"
                                                    [filterable]="true"
                                                    formControlName="ContractEmailID"
                                                    valueField="id">
                                    </kendo-combobox>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4 col-sm-12">
                        <h6>Driver's License Template</h6>
                        <hr />
                        <div class="row margin-top10">
                            <div class="col-lg-12 col-sm-12">
                                <div>
                                    <kendo-combobox class="form-control" [data]="mailMergeTempList"
                                                    [valuePrimitive]="true"
                                                    textField="text"
                                                    [kendoDropDownFilter]="filterSettings"
                                                    [filterable]="true"
                                                    formControlName="dLMMID"
                                                    valueField="id">
                                    </kendo-combobox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                        <h6>Contract Ready to be Counter-Signed</h6>
                        <hr />
                        <div class="row margin-top10">
                            <div class="col-lg-12 col-sm-12">
                                <div>
                                    <kendo-combobox class="form-control" [data]="emailTempList"
                                                    [valuePrimitive]="true"
                                                    textField="text"
                                                    [kendoDropDownFilter]="filterSettings"
                                                    [filterable]="true"
                                                    formControlName="signOffID"
                                                    valueField="id">
                                    </kendo-combobox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="button-group margin-top10">
                    <div class="form-group">
                        <button class="primary-btn" type="submit">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

