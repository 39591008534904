import { SimpleResponse } from "./generic-response.model";

export interface CMContract {
    cmContractID: number;
    clpcompanyID: number;
    clubID: number;
    siteID: number;
    class4Code: number;
    clpUserID: number;
    agentCLPUserID: number;
    cmProductID: number;
    taxRate: number;
    discountSignUp: number;
    discountMembership: number;
    discountDescription: string;
    contractTerm: string;
    specialTerms: string;
    daysOfWeekOnPlan: string;
    daysOfWeekOnPlanList: string[];
    isSeasonal: boolean;
    isDays: boolean;
    productDesc: string;

    monthsOnPlan: string;
    monthsOnPlanList: string[];
    rateIncreasePct: string;
    rateIncreaseTerm: string;
    agentName: string;

    dtContractStart: string;
    dtContractEnd: string;
    dtExpires: string;
    dtPaymentBegins: string;
    dtSignedMembers: string;
    dtSignedClub: string;
    dtCreated: string;
    dtSignedCLPUser: string;
    signedCLPUserID: number;

    dtContractStartLabel: string;
    dtContractEndLabel: string;
    dtExpiresLabel: string;
    dtPaymentBeginsLabel: string;
    dtSignedMembersLabel: string;
    dtSignedClubLabel: string;
    dtCreatedLabel: string;

    isSignUpCC: boolean;
    isSignUpACH: boolean;
    isSignUpCheck: boolean;
    isSignUpCash: boolean;
    isMembershipCC: boolean;
    isMembershipACH: boolean;
    isMembershipCheck: boolean;

    statusID: number;
    members: CMContractMember[];
    fees: CMContractFee[];
    memberfees: CMMemberFee[];

    testMode: boolean;
    sessionId: string;
    intMaxMembers: number;
}

export interface CMContractMember {
    cMContractMemberID: number;
    dtContractStartLabel: number;
    /*cmMContractMemberID: number;*/
    cMMemberID: number;
    cmContractID: number;
    contactID: number;
    statusID: number;

    dteSig: string;
    dtCreated: string;
    dteSigLabel: string;
    dtCreatedLabel: string;

    feeSignUp: number;
    feeMembership: number;
    taxSignUp: number;
    taxMembership: number;

    eSigFullImage: string;
    eSigInitialsImage: string;
    isPrimary: boolean;
    eInitials: string;
    CMMemberFees: CMMemberFee[];
    isExtraMem: boolean;
    dtDL: Date;
}

export interface CMContractSetting {
    cmSiteContractSettingID: number;
    siteID: number;
    isEntryACH: boolean;
    isEntryCC: boolean;
    isEntryCheck: boolean;
    isEntryCash: boolean;
    isMonthlyACH: boolean;
    isMonthlyCC: boolean;
    isMonthlyCheck: boolean;
    isAffirmEntry: boolean;
    CCAuthMMID: number;
    ACHAuthMMID: number;
    CheckAuthMMID: number;
    CashAuthMMID: number;
    ContractEmailID: number;
    dLMMID: number;
    signOffID: number;
    contractAuditorID: number;
}

export interface ContractResponse extends SimpleResponse {
    contracts: CMContract[];
}

export interface CMContractSettingResponse extends SimpleResponse {
    setting: CMContractSetting;
}

export interface ContactDetails {
    contactId: number
    add1: string,
    city: string,
    country: string,
    state: string,
    zip: string,
    mobile: string,
    homePhone: string,
    email: string,
    firstName: string,
    lastName: string,
    signupFee: number,
    memberFee: number,
    custom4: string,
    custom5: string,
    custom6: string,
    custom7: string,
    statusId: number,
    cMMemberID: number,
    cMIsPrimary: boolean,
    dl: string,
    dob: string,
    emergencyCName: string,
    emergencyCPhone: string,
    dtDL: Date
}

export interface Contact {
    contactID: number;
    referralID: number;
    cLPUserID: number;
    cLPCompanyID: number;
    companyID: number;
    firstName: string;
    lastName: string;
    salutation: string;
    title: string;
    companyName: string;
    add1: string;
    add2: string;
    add3: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    custom1: string;
    custom2: string;
    custom3: string;
    custom4: string;
    custom5: string;
    custom6: string;
    custom7: string;
    custom8: string;
    custom9: string;
    custom10: string;
    custom11: string;
    custom12: string;
    custom13: string;
    custom14: string;
    custom15: string;
    custom16: string;
    check1: boolean;
    check2: boolean;
    check3: boolean;
    check4: boolean;
    check5: boolean;
    check6: boolean;
    phone: string;
    homePhone: string;
    mobile: string;
    altPhone: string;
    fax: string;
    otherFax: string;
    eBlastAddress: number;
    email: string;
    email2: string;
    email3: string;
    webSite: string;
    class1Code: number;
    class2Code: number;
    class3Code: number;
    class4Code: number;
    class5Code: number;
    class6Code: number;
    class7Code: number;
    class8Code: number;
    comments: string;
    shareable: boolean;
    hasBeenEdited: boolean;
    outlookSync: boolean;
    dtModified: string;
    dtCreated: string;
    eVID: number;
    isOptOutTxtMsg: number;
    isOptOutEmail: boolean;
    custom17: string;
    custom18: string;
    custom19: string;
    custom20: string;
    mailingId: number;
    requestComment: string;
    requestId: string;
    status: number;
    result: number;
    responded: boolean;
    fromAddress: string;
    userName: string;
    uFirstName: string;
    uLastName: string;
    coCompanyName: string;
    coCompanyID: number;
    dialCode: string;

    dl: string;
    dob: string;
    emergencyCName: string;
    emergencyCPhone: string;
}

export interface CMContractNavListResponse extends SimpleResponse {
    cmContractNav: CMContractNav[];
}

export interface CMContractNav {
    cMContractNavID: number;
    cMContractID: number;
    contactID: number;
    cMProductID: number;
    cMProductDocID: number;
    isAccepted: boolean;
    dtAccepted: string;
    cmProductName: string;
    mailMergeTemplateID: number;
    htmlText: string;
    sOrder: number
}


export interface CMNavigation {
    cMNavigationID: number,
    cMContractID: number,
    contactID: number,
    isDetails: boolean,
    dtDetails: string,
    isClubsideFee: boolean,
    dtClubsideFee: string,
    isMembershipFee: boolean,
    dtMembershipFee: string,
    isPaymentCollect: boolean,
    dtPaymentCollect: string,
    isReview: boolean,
    dtReview: string,
    isSignUpFee: boolean,
    dtSignUpFee: string,
    isDLUploaded: boolean
}


export interface CMNavigationResponse extends SimpleResponse {
    cMNavigation: CMNavigation;
}

export enum eCMNavigation_Update_Type {
    isDetails = 1,
    isReview = 2,
    isPaymentCollect = 3,
    isDLUploaded = 4
}

export interface CC {
    contactId: number,
    cmMemberId: number,
    contractId: number,
    vCustomerId: string,
    memberFeeId: number,
    total: number,
    creditCard: string,
    name: string,
    exp: string,
    cvc: string,
    address: string,
    zipcode: string,
    phone: string,
    email: string,
    city: string,
    state: string,
    country: string,
    txnId: number,
    isAllowOtherMem: boolean,
}

export interface Bank {
    contactId: number,
    cmMemberId: number,
    contractId: number,
    vCustomerId: string,
    total: number,
    routingNumber: string,
    accountNumber: string,
    accountType: string,
    institution: string,
    businessName: string,
    accountName: string,
    accountPhone: string,
    accountEmail: string,
    accountAddress: string,
    accountCity: string,
    accountZip: string,
    accountState: string,
    accountCountry: string
}

export interface CheckCash {
    contactId: number,
    cmMemberId: number,
    contractId: number,
    vCustomerId: string,
    memberFeeId: number,
    isCash: boolean,
    total: number
}

export enum CMMemberStatus {
    None = 0,
    Ready_To_Send = 1,
    Sent = 2,
    Registered = 3,
    Info_Update = 4,
    eSigned = 5,
    Completed = 6,
    UploadedDL = 7,
    Active = 8
}



export interface ContractAcceptation {
    contractNavID: number,
    contractId: number,
    eSigFullImage: string,
    eSigInitialsImage: string,
    eInitials: string,
    eFullName: string
}

export interface CMPaymentTxn {
    cMPaymentTxnID: number;
    cMContractID: number;
    cMMemberID: number;
    memberFeeId: number;
    vCustomerID: string;
    vPaymentMethodID: string;
    vTransactionID: string;
    paymentType: number;
    paymentMethodSummary: string;
    total: number;
    dtCreated: string;
    dtModified: string;
    respDiag: string;
    card4Digits: string;
    displayAmount: string;
    cMPaymentItemID: number,
    feeBase: number,
    feeTotal: number,
    isAllowOtherMem: boolean,
    isPaymentDone: boolean
}


export interface CMPaymentTxnResponse extends SimpleResponse {
    cmPaymentTxnList: CMPaymentTxn[];
    totalPaymentByCard: any

}

export enum eContractFeeType {
    All = 0,
    SignUpFee = 1,
    MembershipFee = 2
}

export interface CMContractFee {
    cMContractFeeID: number,
    cMProductFeeID: number,
    cMProductID: number,
    cMContractID: number,
    cMFeeName: string,
    isCollectNow: boolean,
    isVariable: boolean,
    isTaxable: boolean,
    isRecurring: boolean,
    recursEvery: number; //(1 = day, 2 = month, 3 = year)
    recursEveryInterval: number; //ex: 1 day, 3 weeks, 6 months, 1 year
    isPaymentSplitable: boolean; //can it be split betweem payment methods
    memberSplitable: number; //1 = paid by one member, 2 = split by each member, 3 = paid by every member
    feeBase: number,
    feeTax: number,
    feeTotal: number,
    sOrder: number,
}

export interface FeeButton {
    cMContractFeeID: number,
    cMProductFeeID: number,
    cMProductID: number,
    cMFeeName: string,
}

export interface CMContractFeeListResponse extends SimpleResponse {
    contractFees: CMContractFee[];
}

export interface CMMemberFee {
    cmContractFeeID: number,
    cmProductFeeID: number,
    cmProductID: number,
    cmMemberFeeID: number,
    cmFeeName: string,
    feeTotal: number,
    feeTax: number,
    isTaxable: boolean,
    isRecurring: boolean,
    memberID: number,
    feeBase: number,
    contactID: number,
    sOrder: number,
    isDone: boolean,
    memberSplitable: number,
    isPaymentSplitable: boolean,
    isCollectNow: boolean
}

export interface CMMemberFeeListResponse extends SimpleResponse {
    memberFees: CMMemberFee[];
}

export interface CMContractListResponse extends SimpleResponse {
    contracts: CMContract[];
}

export interface PaymentReview {
    paymentType: number,
    html: string
}

export interface PaymentReviewListResponse extends SimpleResponse {
    paymentReviews: PaymentReview[]
}

export interface CMPaymentItem {
    cMPaymentItemID: number,
    cMPaymentTxnID: number,
    cMContractFeeID: number,
    cMMemberFeeID: number,
    feeBese: number,
    feeTotal: number,
    isPaymentDone: boolean,
    dtModified: string

}

export interface CMPaymentItem_Charge {
    cMPaymentItemID: number;
    cMPaymentTxnID: number;
    cMContractFeeID: number;
    cMMemberFeeID: number;
    isPaymentDone: boolean;
    vCustomerID: string;
    vPaymentMethodID: string;
    vTransactionID: string;
    paymentType: number;
    paymentMethodSummary: string;
    feeTotal: number;
}

export interface ContractTextMessage {
    toContactID: number;
    contractID: number;
    clpCompanyID: number;
    clpUserID: number;
    sclpUserID: number;
    txtMsg: string;
    mediaURL: string;
    sendEmail: boolean;
}

export interface PayListResponse {
    payList: Pay[];
}

export interface Pay {
    txnId: number;
    paymentMethodSummary: string;
    isPaymentDone: boolean;
    isCollectNow: boolean;
    paymentType: number;
    Total: number;
    subFees: FeeItem[];
}

export interface FeeItem {
    feeName: string;
    feeBase: number;
    feeTax: number;
    feeTotal: number;
}

export interface ContractPdfConvertContainer {
    id: number;
    companyId: number;
    contractId: number;
    contactId: number;
    siteId: number;
    fileName: string;
    fileLength: number;
    processed: boolean,
    isAI: boolean;
    type: number;
}


export interface RetrieveCardResponse extends SimpleResponse {
    stripCardDetails: BillingDetails;
    cardDetails: Card;
}

export interface BillingDetails {
    address: Address;
    email: any;
    name: any;
    phone: any;
}

export interface Address {
    city: any;
    country: any;
    line1: any;
    line2: any;
    postal_code: any;
    state: any;
}


export interface Card {
    brand: string;
    checks: Checks;
    country: string;
    display_brand: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    generated_from: any;
    last4: string;
    networks: Networks;
    three_d_secure_usage: ThreeDSecureUsage;
    wallet: any;
}

export interface Checks {
    address_line1_check: any;
    address_postal_code_check: any;
    cvc_check: string;
}

export interface Networks {
    available: string[];
    preferred: any;
}

export interface ThreeDSecureUsage {
    supported: boolean;
}

export interface CMContractLstResponse extends SimpleResponse {
    contractList: CMContractItem[];
}

export interface CMContractItem {
    cmContractID: number;
    Members: string[];
    contactName: string;
    isPrimary: boolean;
    productDesc: string;
    siteName: string;
    status: string;
    dtCreated: string;
    dtContractStart: string;
    dtContractEnd: string;
    dtPaymentBegins: string;
    dteSig: string;
    strdtContractStart: string;
    strdtContractEnd: string;
    strdtPaymentBegins: string;
    strdteSig: string;
    fees: string;
    siteID: number;
    class4Code: number;
    statusID: number;
}

export interface CMContractListRequest {
    timeFrame: number;
    sites: number[];
    users: number[];
    contractStatus: eContractStatus;
    strdtStart: string;
    strdtEnd: string;
    dtStart: string;
    dtEnd: string;
    clubId: number;
}

export enum eContractStatus {
    None = 0,
    Configured = 1,
    In_Progress = 2,
    Members_Signed = 3,
    On_Hold_Review = 4,
    Waiting_Countersign = 5,
    Active = 6,
    In_Active = 7,
    All = 10
}

export interface CMContractLog {
    cmContractID: number;
    clpUserID: number;
    sclpUserID: number;
    contactID: number;
    isFaceToFace: number;
    notes: string;
    sessionID: string;
}

export interface CMContractLogDisplay {
    cmContractID: number;
    clpUserID: number;
    contactID: number;
    isFaceToFace: string;
    notes: string;
    sessionID: string;
    dtCreated: string;
    contactName: string;
    userName: string;
}

export interface CMSession {
    cmContractID: number;
    sessionID: string;
}


export interface CMContractLogListResponse extends SimpleResponse {
    contractLogList: CMContractLogDisplay[];
}

export interface CMAPILogListResponse extends SimpleResponse {
    apiLogList: CMAPILog[];
}

export interface CMAPILog {
    vendorName: string;
    cMContractID: number;
    endPoint: string;
    rawJSON: string;
    rawResponse: string;
    diagnostics: string;
    contactName: string;
    dtCreated: string;
}

export interface VendorCustrReq {
    contractId: number;
    contactId: number;
    cmMemberId: number;
    email: string;
}

export enum eREF_CMContractStatus {
    None = 0,
    Configured = 1,
    In_Progress = 2,
    Members_Signed = 3,
    OnHold_Review = 4,
    Waiting_CounterSign = 5,
    Active = 6,
    InActive = 7
}


export enum eREF_CMMemberStatus {
    None = 0,
    Ready_To_Send = 1,
    Sent = 2,
    Registered = 3,
    Info_Update = 4,
    eSigned = 5,
    Payment_Complete = 6,
    Uploaded_Drivers_License = 7,
    Active = 8
}
