import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CMDashBoardResponse } from '../models/cm-dashboard.modal';
import { delayedRetryHttp } from './shared/delayed-retry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class CmDashboardService {
    private baseUrl: string;
    private api: string = 'api/CMDashBoard';
    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async CMDashBoard_byClubGet(encryptedUser: string, cmClubID: number, timeFrame: number, cmSiteID: number, strdtStart: string, strdtEnd: string, isAI: boolean): Promise<CMDashBoardResponse | void> {
        const a = await this.httpClient
            .get<CMDashBoardResponse>(`${this.baseUrl}/CMDashBoard_byClubGet/${cmClubID}/${timeFrame}/${cmSiteID}?strdtStart=${strdtStart}&strdtEnd=${strdtEnd}&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cmClubID - " + cmClubID + "," + "timeFrame - " + timeFrame + "," + "strdtStart - " + strdtStart + "," + "strdtEnd-" + strdtEnd, encryptedUser, "CmDashboardService", "CMDashBoard_byClubGet"); });
        return a;
    }
}
