<div class="contact-manager">
    <div class="main-wrapper-section">
        <div class="common-header contract-header">
            <div class="text-left width-33 float-left"><h3>{{primaryContactName}}</h3></div>
            <div class="text-right width-66 float-right">
                <div class="button-group">
                    <!--<button (click)="saveSignature()" [hidden]="!isSignAccept || contractStatusId != 5" class="primary-btn">Accept</button>-->
                    <button [hidden]="user?.slurpyUserId == 0 || contractId == 0 || contractStatusId < 5" (click)="archive()" class="primary-btn margin-left15">Archive PDFs</button>
                    <button [hidden]="contractId == 0" (click)="openLog('contract')" class="primary-btn margin-left15">Contract Log</button>
                    <button [hidden]="user?.slurpyUserId == 0 || contractId == 0" (click)="openLog('activity')" class="primary-btn margin-left15">API Log</button>
                    <button [hidden]="!contractEdit" class="primary-btn margin-left15" (click)="saveContract()">Save Contract <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                    <button [hidden]="contractId <= 0 || contractEdit || disableSaveContract" class="primary-btn margin-left15" (click)="EditContract()">Edit Contract</button>
                    <button [hidden]="contractId <= 0 || !contractEdit" class="primary-btn-delete margin-left15" (click)="confirmDelete()">Delete</button>
                </div>
            </div>
        </div>
        <div class="card-section">
            <!--<div class="card-section contract-main-section">
            <div class="card-panel contract-left-panel">-->
            <div class="card-panel">
                <div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-12"><h5>Contract Details</h5></div>
                        <div class="col-lg-8 col-sm-12">
                            <div class="flex-display">
                                <button type="button" [disabled]="isDownloadedDL" [hidden]="isShowDLDownload()" (click)="downloadDL()" class="primary-btn margin-left15">
                                    Download Driver's License &nbsp;<i class="fa-solid fa-download"></i>&nbsp;
                                    <i class="fa fa-spinner fa-spin" [hidden]="!isDownloadedDL"></i>
                                </button>
                                <button type="button" [disabled]="isDownloaded" [hidden]="contractStatusId < 5" (click)="downloadContract()" class="primary-btn margin-left15">
                                    Download Contract&nbsp;<i class="fa-solid fa-download"></i>&nbsp;
                                    <i class="fa fa-spinner fa-spin" [hidden]="!isDownloaded"></i>
                                </button>
                                <button type="button" [disabled]="isReview" [hidden]="!isShowReview" (click)="review();" class="primary-btn margin-left15">
                                    Reviewed&nbsp;<i class="fa fa-spinner fa-spin" [hidden]="!isReview"></i>
                                </button>
                                <i class="fa fa-refresh margin-left15" title="Refresh Contract" [ngClass]="showSpinner ? 'fa-spin' : ''" (click)="refreshContract(true)" style="font-size:20px; cursor:pointer"></i>
                                <h5 class="mb-0" style="margin-left:5px;"> Status: <span [ngClass]="contractStatusId == 5 || contractStatusId == 6 ? 'active-product' : 'not-active-product'">{{ contractStatusId == 0 ? 'None' : contractStatusId == 1 ? 'Configured' : contractStatusId == 2 ? 'In Progress' : contractStatusId == 3 ? 'Member Signed' :contractStatusId == 4 ? 'On Hold Review' : contractStatusId == 5 ? 'Contract Waiting Countersign' : contractStatusId == 6 ? 'Active' : 'In-Active' }}</span></h5>

                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="contract-pad" [ngClass]="!isSignVisible ? 'signpad-hide' : ''">
                        <div class="">
                            <div class="sign-bg">
                                <signature-pad #sigpadFinal class="signature-pad" id="signFinal" [options]="signataurePadOptions" (onEndEvent)="signatureFinalOnEnd()" title=""></signature-pad>
                                <div class="display-flex margin-top10" style="align-items:center">
                                    <button (click)="saveSignature()" class="primary-btn">Accept & Sign <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                    <button (click)="cMSiteEmployees_Signature_Get()" class="primary-btn">Last Signature<i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                    <button class="primary-btn" (click)="drawClearFinal()" title="Clear Signature">Clear Signature <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="form-group col-sm-3" [hidden]="!contractEdit">
                            <label>Product</label>
                            <kendo-combobox class="form-control min-width300" [data]="productList"
                                            [valuePrimitive]="true"
                                            textField="productName"
                                            [kendoDropDownFilter]="filterSettings"
                                            [clearButton]="false"
                                            [filterable]="true"
                                            (valueChange)="productChange($event)"
                                            [(ngModel)]="productSelected"
                                            valueField="class4Code">
                            </kendo-combobox>
                        </div>
                        <div class="form-group col-sm-3" [hidden]="contractEdit">
                            <label class="fldtitle">Product</label>
                            <input name="SignupFee" [(ngModel)]="productSelectedName" [ngModelOptions]="{standalone: true}" type="text" autocomplete="off" id="productSelectedName" class="form-control" readonly>
                        </div>
                        <div class="form-group col-sm-3" [hidden]="!contractEdit">
                            <label class="fldtitle">Site</label><br>
                            <kendo-combobox class="form-control min-width300" [data]="siteList"
                                            [valuePrimitive]="true"
                                            textField="siteName"
                                            [kendoDropDownFilter]="filterSettings"
                                            [clearButton]="false"
                                            [filterable]="true"
                                            (valueChange)="siteChange($event)"
                                            [(ngModel)]="siteSelected"
                                            valueField="siteID">
                            </kendo-combobox>
                        </div>
                        <div class="form-group col-sm-3" [hidden]="contractEdit">
                            <label class="fldtitle">Site</label>
                            <input name="SignupFee" [(ngModel)]="siteSelectedName" [ngModelOptions]="{standalone: true}" type="text" autocomplete="off" id="siteSelectedName" class="form-control" readonly>
                        </div>
                        <div class="form-group col-sm-3">
                            <form [formGroup]="agentForm">
                                <label class="fldtitle">Agent Name</label>
                                <input name="agentName" formControlName="agentName" type="text" autocomplete="off" id="agentName" class="form-control" readonly>
                            </form>
                        </div>
                        <div class="form-group col-sm-3">
                            <form [formGroup]="testModeForm">
                                <div class="custom-control custom-switch payment-process-check">
                                    <input type="checkbox" class="custom-control-input" id="processorTestMode" formControlName="processorTestMode">
                                    <label class="custom-control-label custom-control-label-disabled" for="processorTestMode">Processor Test Mode</label>
                                </div>
                            </form>
                        </div>
                    </div>
                    <form [formGroup]="siteTaxForm" class="contract-manager">
                        <div formArrayName="fees">
                            <div class="row">
                                <div class="form-group col-sm-3" *ngFor="let fee of siteTaxForm.get('fees')['controls']; let i=index">
                                    <div [formGroupName]="i">
                                        <div class="">
                                            <label class="fldtitle">{{fee.value.cmFeeName}}</label>
                                            <input name="feeAmount" formControlName="feeAmount" type="text" (blur)="onChangeFees(fee,i, false)" autocomplete="off" id="feeAmount" class="form-control" appCurrency [readonly]="!this.contractEdit" [hidden]="isFeeHidden(fee)">
                                            <input name="feeAmount0" type="text" id="feeAmount0" class="form-control" [hidden]="!isFeeHidden(fee)" value="Get card on file" readonly>
                                        </div>
                                        <div class="fee-tax" [hidden]="!fee.value.isTaxable">
                                            <label class="fldtitle">{{fee.value.cmFeeName}} Tax</label>
                                            <input name="feeTax" formControlName="feeTax" type="text" autocomplete="off" id="feeTax" class="form-control" appCurrency readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-3">
                                <label class="fldtitle">Contract Start</label>
                                <input formControlName="contractStartLabel" type="text" autocomplete="off" class="form-control" [hidden]="contractEdit" readonly>
                                <mat-form-field [hidden]="!contractEdit">
                                    <input matInput #ref [matDatepicker]="contractStart" class="form-control" (dateChange)="contractStartChange($event)" formControlName="contractStart" id="contractStart">
                                    <mat-datepicker-toggle matSuffix [for]="contractStart"></mat-datepicker-toggle>
                                    <mat-datepicker #contractStart></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-3">
                                <label class="fldtitle">Payment Begins</label>
                                <input formControlName="paymentBeginLabel" type="text" autocomplete="off" class="form-control" [hidden]="contractEdit" readonly>
                                <mat-form-field [hidden]="!contractEdit">
                                    <input matInput #ref [matDatepicker]="paymentBegin" class="form-control" formControlName="paymentBegin" (dateChange)="contractBeginsChange($event)" id="paymentBegin">
                                    <mat-datepicker-toggle matSuffix [for]="paymentBegin"></mat-datepicker-toggle>
                                    <mat-datepicker #paymentBegin></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-3">
                                <form [formGroup]="termForm">
                                    <label class="fldtitle">Term (Months)</label>
                                    <input name="term" formControlName="term" type="text" autocomplete="off" id="term" class="form-control" mask="00" (change)="changeTerm()" [readonly]="!contractEdit">
                                </form>
                            </div>
                            <div class="form-group col-sm-3">
                                <label class="fldtitle">Contract Ends (Payment Begins + Term)</label>
                                <input formControlName="contractEndLabel" type="text" autocomplete="off" class="form-control" readonly>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-3">
                                <label class="fldtitle">Contract Expires</label>
                                <input formControlName="contractExpiresLabel" type="text" autocomplete="off" class="form-control" [hidden]="contractEdit" readonly>
                                <mat-form-field [hidden]="!contractEdit">
                                    <input matInput #ref [matDatepicker]="contractExpires" class="form-control" formControlName="contractExpires" id="contractExpires">
                                    <mat-datepicker-toggle matSuffix [for]="contractExpires"></mat-datepicker-toggle>
                                    <mat-datepicker #contractExpires></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-3">
                                <label class="fldtitle">Contract Signed</label>
                                <input name="ContractSigned" formControlName="dtSignedMembers" type="text" autocomplete="off" id="ContractSigned" class="form-control" readonly>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-12">
                                <label class="fldtitle">Product Description</label>
                                <div class="rate-sheet-description" [innerHtml]="getProductDescription()"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-12">
                                <label class="fldtitle">Special Terms</label>
                                <textarea name="specialterms" formControlName="specialterms" rows="2" cols="20" maxlength="2000" id="specialterms" class="form-control" [readonly]="!contractEdit"></textarea>
                            </div>
                        </div>
                        <div class="row row-buffer-top" [hidden]="!isDays">
                            <div class="form-group col-sm-12">
                                <label class="fldtitle">Select Days of the Week on Plan</label>
                            </div>
                        </div>
                        <div class="row row-buffer-top" [hidden]="isDays">
                            <div class="form-group col-sm-12">
                                <label class="fldtitle">Days of the Week on Plan</label>
                            </div>
                        </div>
                        <div class="row margin-left-none margin-topm10">
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isMonday" *ngIf="!contractEdit" formControlName="isMonday">
                                    <input type="checkbox" class="custom-control-input" [ngClass]="isDays ? '' : 'custom-control-input-disabled'" id="isMonday" formControlName="isMonday" *ngIf="contractEdit" name="isMonday">
                                    <label class="custom-control-label custom-control-label-disabled" for="isMonday">Mon</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isTuesday" *ngIf="!contractEdit" formControlName="isTuesday">
                                    <input type="checkbox" class="custom-control-input" [ngClass]="isDays ? '' : 'custom-control-input-disabled'" id="isTuesday" *ngIf="contractEdit" formControlName="isTuesday">
                                    <label class="custom-control-label custom-control-label-disabled" for="isTuesday">Tues</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isWednesday" *ngIf="!contractEdit" formControlName="isWednesday">
                                    <input type="checkbox" class="custom-control-input" [ngClass]="isDays ? '' : 'custom-control-input-disabled'" id="isWednesday" *ngIf="contractEdit" formControlName="isWednesday">
                                    <label class="custom-control-label custom-control-label-disabled" for="isWednesday">Wed</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isThursday" *ngIf="!contractEdit" formControlName="isThursday">
                                    <input type="checkbox" class="custom-control-input" [ngClass]="isDays ? '' : 'custom-control-input-disabled'" id="isThursday" *ngIf="contractEdit" formControlName="isThursday">
                                    <label class="custom-control-label custom-control-label-disabled" for="isThursday">Thu</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isFriday" *ngIf="!contractEdit" formControlName="isFriday">
                                    <input type="checkbox" class="custom-control-input" [ngClass]="isDays ? '' : 'custom-control-input-disabled'" id="isFriday" *ngIf="contractEdit" formControlName="isFriday">
                                    <label class="custom-control-label custom-control-label-disabled" for="isFriday">Fri</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isSaturday" *ngIf="!contractEdit" formControlName="isSaturday">
                                    <input type="checkbox" class="custom-control-input" [ngClass]="isDays ? '' : 'custom-control-input-disabled'" id="isSaturday" *ngIf="contractEdit" formControlName="isSaturday">
                                    <label class="custom-control-label custom-control-label-disabled" for="isSaturday">Sat</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isSunday" *ngIf="!contractEdit" formControlName="isSunday">
                                    <input type="checkbox" class="custom-control-input" [ngClass]="isDays ? '' : 'custom-control-input-disabled'" id="isSunday" *ngIf="contractEdit" formControlName="isSunday">
                                    <label class="custom-control-label custom-control-label-disabled" for="isSunday">Sun</label>
                                </div>
                            </div>
                        </div>
                        <div class="row row-buffer-top" [hidden]="!isSeasonal">
                            <div class="form-group col-sm-12">
                                <label class="fldtitle">Select Months on Plan</label>
                            </div>
                        </div>
                        <div class="row margin-left-none margin-topm10" [hidden]="!isSeasonal">
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isJanuary" *ngIf="!contractEdit" formControlName="isJanuary">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isJanuary" formControlName="isJanuary">
                                    <label class="custom-control-label custom-control-label-disabled" for="isJanuary">Jan</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isFebruary" *ngIf="!contractEdit" formControlName="isFebruary">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isFebruary" formControlName="isFebruary">
                                    <label class="custom-control-label custom-control-label-disabled" for="isFebruary">Feb</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isMarch" *ngIf="!contractEdit" formControlName="isMarch">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isMarch" formControlName="isMarch">
                                    <label class="custom-control-label custom-control-label-disabled" for="isMarch">Mar</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isApril" *ngIf="!contractEdit" formControlName="isApril">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isApril" formControlName="isApril">
                                    <label class="custom-control-label custom-control-label-disabled" for="isApril">Apr</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isMay" *ngIf="!contractEdit" formControlName="isMay">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isMay" formControlName="isMay">
                                    <label class="custom-control-label custom-control-label-disabled" for="isMay">May</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isJune" *ngIf="!contractEdit" formControlName="isJune">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isJune" formControlName="isJune">
                                    <label class="custom-control-label custom-control-label-disabled" for="isJune">Jun</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isJuly" *ngIf="!contractEdit" formControlName="isJuly">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isJuly" formControlName="isJuly">
                                    <label class="custom-control-label custom-control-label-disabled" for="isJuly">Jul</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isAugust" *ngIf="!contractEdit" formControlName="isAugust">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isAugust" formControlName="isAugust">
                                    <label class="custom-control-label custom-control-label-disabled" for="isAugust">Aug</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isSeptember" *ngIf="!contractEdit" formControlName="isSeptember">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isSeptember" formControlName="isSeptember">
                                    <label class="custom-control-label custom-control-label-disabled" for="isSeptember">Sep</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isOctober" *ngIf="!contractEdit" formControlName="isOctober">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isOctober" formControlName="isOctober">
                                    <label class="custom-control-label custom-control-label-disabled" for="isOctober">Oct</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isNovember" *ngIf="!contractEdit" formControlName="isNovember">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isNovember" formControlName="isNovember">
                                    <label class="custom-control-label custom-control-label-disabled" for="isNovember">Nov</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isDecember" *ngIf="!contractEdit" formControlName="isDecember">
                                    <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isDecember" formControlName="isDecember">
                                    <label class="custom-control-label custom-control-label-disabled" for="isDecember">Dec</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="payment-method-contract">
                    <mat-accordion class="payment-method-panel">
                        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                            <mat-expansion-panel-header>
                                <mat-panel-title> Accepted Payment Methods </mat-panel-title>
                                <mat-panel-description>
                                    <div><mat-icon>payments</mat-icon></div>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <form [formGroup]="paymentForm">
                                <div class="payment-grid">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Non-Recurring Fees</th>
                                                <th>Recurring Fees</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Credit Card</td>
                                                <td>
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isEntryCC" *ngIf="!contractEdit" formControlName="isEntryCC">
                                                        <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isEntryCC" formControlName="isEntryCC">
                                                        <label class="custom-control-label custom-control-label-disabled" for="isEntryCC"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isMonthlyCC" *ngIf="!contractEdit" formControlName="isMonthlyCC">
                                                        <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isMonthlyCC" formControlName="isMonthlyCC">
                                                        <label class="custom-control-label custom-control-label-disabled" for="isMonthlyCC"></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>ACH</td>
                                                <td>
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isEntryACH" *ngIf="!contractEdit" formControlName="isEntryACH">
                                                        <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isEntryACH" formControlName="isEntryACH">
                                                        <label class="custom-control-label custom-control-label-disabled" for="isEntryACH"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isMonthlyACH" *ngIf="!contractEdit" formControlName="isMonthlyACH">
                                                        <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isMonthlyACH" formControlName="isMonthlyACH">
                                                        <label class="custom-control-label custom-control-label-disabled" for="isMonthlyACH"></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Physical Check</td>
                                                <td>
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isEntryCheck" *ngIf="!contractEdit" formControlName="isEntryCheck">
                                                        <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isEntryCheck" formControlName="isEntryCheck">
                                                        <label class="custom-control-label custom-control-label-disabled" for="isEntryCheck"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isMonthlyCheck" *ngIf="!contractEdit" formControlName="isMonthlyCheck">
                                                        <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isMonthlyCheck" formControlName="isMonthlyCheck">
                                                        <label class="custom-control-label custom-control-label-disabled" for="isMonthlyCheck"></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cash</td>
                                                <td>
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" onclick="return false" class="custom-control-input custom-control-input-disabled" id="isEntryCash" *ngIf="!contractEdit" formControlName="isEntryCash">
                                                        <input type="checkbox" *ngIf="contractEdit" class="custom-control-input" id="isEntryCash" formControlName="isEntryCash">
                                                        <label class="custom-control-label custom-control-label-disabled" for="isEntryCash"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>

        <div class="new-member">
            <div class="common-header contract-header" [hidden]="!contractEdit || !checkMaxMem()">
                <div class="text-left width-50 float-left"></div>
                <div class="text-right width-50 float-right">
                    <div class="button-group">
                        <button class="primary-btn" [hidden]="disableSaveContract" (click)="openContact(true)">Add Member <i class="fa fa-plus"></i></button>
                    </div>
                </div>
            </div>
            <hr style="border-top: transparent;" />
            <div class="new-member-section">
                <div class="new-member-panel text-right"><span [hidden]="feeButtonList?.length == 0 || contacts?.length == 1">Split Evenly:</span></div>
                <div class="new-member-panel text-center">
                    <div class="split-btn-panel" *ngFor="let f of feeButtonList; let bi=index;">
                        <button class="btn edit-btn" [disabled]="contractStatusId == 5 || contractStatusId == 6 || !contractEdit" (click)="setEvenly(f)" *ngIf="contacts?.length > 1">{{f.cMFeeName}}</button>
                    </div>
                </div>
                <div class="new-member-panel"></div>
            </div>
            <div [formGroup]="membersForm">
                <div formArrayName="members">
                    <div class="new-member-section" *ngFor="let mem of membersForm.get('members')['controls']; let i=index" [formGroupName]="i">
                        <div class="new-member-panel">
                            <h6 *ngIf="isAI"><b><a href="{{mySoLink}}bcm-redirect?r={{encryptedUser}}&url=/contact/{{user?.cLPUserID}}/{{mem.get('contactId')?.value}}" target="_blank">{{ mem.get('firstName')?.value}} {{mem.get('lastName')?.value}}</a></b></h6>
                            <h6 *ngIf="!isAI"><b><a href="{{mySoLink}}contact/viewnew.aspx?cid={{mem.get('contactId')?.value}}" target="_blank">{{ mem.get('firstName')?.value}} {{mem.get('lastName')?.value}}</a></b></h6>
                            <div class="address-panel">
                                <p *ngIf="mem.get('add1')?.value">{{ mem.get('add1')?.value}}</p>
                                <p *ngIf="mem.get('city')?.value && mem.get('state')?.value && mem.get('zip')?.value">
                                    <span *ngIf="mem.get('city')?.value">{{mem.get('city')?.value}},</span>
                                    <span *ngIf="mem.get('state')?.value">{{mem.get('state')?.value}} </span>
                                    <span *ngIf="mem.get('zip')?.value">{{mem.get('zip')?.value}}</span>
                                </p>
                                <p><b>E:</b> {{mem.get('email')?.value}}</p>
                                <p><b>P:</b> {{mem.get('mobile')?.value}}</p>
                            </div>
                        </div>
                        <div class="new-member-fee-panel">
                            <div formArrayName="memberFees">
                                <div class="contract-product-panel">
                                    <div class="" *ngFor="let fee of mem?.get('memberFees')['controls']; let j=index">
                                        <div class="contract-product-fee" [formGroupName]="j">
                                            <div class="form-group">
                                                <label>{{fee.get('cMFeeName')?.value}}</label>
                                                <input class="form-control" formControlName="feeBase" type="text" appCurrency (change)="feeChange(fee)" [readonly]="isFeeBaseReadOnly(mem, fee)" [hidden]="isFeeHidden(fee)" />
                                                <input class="form-control" type="text" value="Get card on file" readonly [hidden]="!isFeeHidden(fee)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="new-member-panel">
                            <div class="action-btn">
                                <i class="fa fa-pencil" [hidden]="!contractEdit" (click)="openContactForm(mem.get('contactId')?.value)"></i>
                                <i class="fa fa-trash" [hidden]="!contractEdit" *ngIf="mem.get('isPrimary')?.value == false && mem.get('statusId')?.value < 6 " (click)="confirmMemberDlt(mem.get('cMMemberID')?.value,mem.get('contactId')?.value, i)"></i>
                            </div>
                            <p>

                                Status:
                                <span [ngClass]="getStatus(mem.get('statusId')?.value) == 'Active' ? 'comp-text-complete' : 'comp-text'">
                                    {{getStatus(mem.get('statusId')?.value)}}
                                </span>
                            </p>
                            <!--<button class="primary-btn" (click)="sendMemberEmail(mem.get('contactId')?.value , i)" [disabled]="mem.get('cMMemberID')?.value == 0">Send Email</button>-->
                            <button class="primary-btn margin-left15" [disabled]="mem.get('cMMemberID')?.value == 0 || ( mem.get('statusId')?.value == 8 && contractStatusId == 6)" (click)="sendMemberText(mem.get('contactId')?.value, i, mem.get('firstName')?.value + ' ' + mem.get('lastName')?.value)">Send Contract</button>
                            <button class="primary-btn margin-left15" [disabled]="mem.get('cMMemberID')?.value == 0 || (mem.get('statusId')?.value == 8 && contractStatusId == 6)" (click)="redirctf2f(mem.get('contactId')?.value, mem.get('firstName')?.value + ' ' + mem.get('lastName')?.value)">Face to Face</button>
                            <!--<div class="copy-text">
                                <a (click)="copyF2FUrl(mem.get('contactId')?.value)" [ngClass]="{'copied': mem.get('isF2FLinkCopied')?.value}">
                                    <i [ngClass]="mem.get('isF2FLinkCopied')?.value ? 'fas fa-copy copied' : 'fas fa-copy'"></i>
                                    <!--<span>{{ mem.get('isF2FLinkCopied')?.value ? 'Copied' : 'Copy' }}</span>
                                </a>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button [hidden]="!contractEdit" type="submit" class="primary-btn" (click)="saveContract()">Save Contract <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
    </div>
</div>
<div id="contactModal" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" style="height:calc(100vh - 10px);">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{isEditMem ? 'Edit Member' : 'Add Member'}} </h4>
                <button type="button" class="close" (click)="hideModalContact()">&times;</button>
            </div>
            <div class="modal-body">
                <div class="edit-panel" style="padding-left:0px;">
                    <div class="edit-colunm full-width">
                        <div class="row row-extended">
                            <div class="col-md-6">
                                <div class="display-flex" *ngIf="!isEditMem">
                                    <div class="display-flex">
                                        <kendo-autocomplete #autocomplete class="auto-width" [data]="searchList" valueField="searchText" placeholder="Search" (valueChange)="goToLink($event)"
                                                            (keydown.enter)="goToLink($event)" (keyup)="getSearchData($event.target.value)" style="min-height:40px;"></kendo-autocomplete>
                                    </div>
                                    <button class="primary-btn margin-left15" (click)="contactHide()">Create Contact <i class="fa fa-plus"></i></button>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="!isContactCreate">
                            <form [formGroup]="contactForm">
                                <div class="row row-extended" style="margin-top:20px;">
                                    <div class="col-sm-6">
                                        <label class="fldtitle">First Name<span style="color:red">*</span></label>
                                        <input type="text" name="txtFirstName" formControlName="txtFirstName" autocomplete="off" id="txtFirstName" class="form-control">
                                        <div *ngIf="contactForm.controls.txtFirstName.errors && (contactForm.controls.txtFirstName.touched || contactForm.controls.txtFirstName.dirty)">
                                            <div class="login-error" *ngIf="contactForm.controls.txtFirstName.errors.required">First name is required. </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Last Name<span style="color:red">*</span></label>
                                        <input type="text" name="txtLastName" formControlName="txtLastName" autocomplete="off" id="txtLastName" class="form-control">
                                        <div *ngIf="contactForm.controls.txtLastName.errors && (contactForm.controls.txtLastName.touched || contactForm.controls.txtLastName.dirty)">
                                            <div class="login-error" *ngIf="contactForm.controls.txtLastName.errors.required">Last name is required. </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-extended" style="margin-top:20px;">
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Email<span style="color:red">*</span></label>
                                        <input type="text" name="txtEmail" formControlName="txtEmail" autocomplete="off" id="txtEmail" class="form-control">
                                        <div *ngIf="contactForm.controls.txtEmail.errors && (contactForm.controls.txtEmail.touched || contactForm.controls.txtEmail.dirty)">
                                            <div class="login-error" *ngIf="contactForm.controls.txtEmail.errors.required">Email is required. </div>
                                            <div class="login-error" *ngIf="contactForm.controls.txtEmail.errors.pattern">Invalid Email</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Mobile<span style="color:red">*</span></label>
                                        <input type="text" name="txtMobilePhone" formControlName="txtMobilePhone" autocomplete="off" id="txtMobilePhone" class="form-control" [placeholder]="placeHolder" [mask]="mobile_mask">
                                        <div *ngIf="contactForm.controls.txtMobilePhone.errors && (contactForm.controls.txtMobilePhone.touched || contactForm.controls.txtMobilePhone.dirty)">
                                            <div class="login-error" *ngIf="contactForm.controls.txtMobilePhone.errors.required">Mobile number is required. </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-extended" style="margin-top:20px;">
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Home Phone</label>
                                        <input type="text" name="txtHomePhone" formControlName="txtHomePhone" [mask]="mobile_mask" autocomplete="off" id="txtHomePhone" class="form-control">
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Address</label>
                                        <input type="text" name="txtAddress" formControlName="txtAddress" autocomplete="off" value="Add 1" id="txtAddress" class="form-control">
                                    </div>
                                </div>
                                <div class="row row-extended" style="margin-top:20px;">
                                    <div class="col-sm-6">
                                        <label class="fldtitle">City</label>
                                        <input type="text" name="txtCity" formControlName="txtCity" autocomplete="off" value="NEW YORK" id="txtCity" class="form-control">
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Zip</label>
                                        <input type="text" name="txtZip" formControlName="txtZip" minLength="3" maxLength="7" autocomplete="off" value="247001" id="ContentPlaceHolder1_txtZip" class="form-control">
                                    </div>
                                </div>
                                <div class="row row-extended" style="margin-top:20px;">
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Country</label>
                                        <kendo-combobox [data]="countryDD"
                                                        class="form-control min-width300"
                                                        [valuePrimitive]="true"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        textField="country_Name"
                                                        formControlName="country"
                                                        valueField="country_Code"
                                                        (valueChange)="onChangeCountry($event)">
                                        </kendo-combobox>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fldtitle">State</label>
                                        <kendo-combobox [data]="stateList"
                                                        class="form-control min-width300"
                                                        [valuePrimitive]="true"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        formControlName="state"
                                                        textField="text"
                                                        valueField="value">
                                        </kendo-combobox>
                                    </div>
                                </div>
                            </form>

                            <form [formGroup]="contactForm2">
                                <div class="row row-extended" style="margin-top:20px;">
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Driver's License</label>
                                        <input type="text" name="dl" formControlName="dl" autocomplete="off" id="dl" class="form-control">
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Date of Birth</label>
                                        <!--<kendo-datepicker formControlName="dob" [format]="format"></kendo-datepicker>-->
                                        <input type="text" name="dl" formControlName="dob" autocomplete="off" id="dob" class="form-control">
                                    </div>
                                </div>
                                <div class="row row-extended" style="margin-top:20px;">
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Emergency Contact Name</label>
                                        <input type="text" name="emgContactName" formControlName="emgContactName" autocomplete="off" id="emgContactName" class="form-control">
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Emergency Contact Phone</label>
                                        <input type="text" name="txtEmgCntPhone" [mask]="mobile_mask" (keypress)="validateNumber($event)" formControlName="txtEmgCntPhone" autocomplete="off" id="txtEmgCntPhone" class="form-control">
                                    </div>
                                </div>
                                <div class="row row-extended" style="margin-top:20px;">
                                    <div class="col-sm-6">
                                    </div>
                                    <div class="col-sm-6 text-right">
                                        <button class="primary-btn" type="button" *ngIf="contactId != fromContactId" (click)="importPrimary()">Import Primary Member Info</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="form-group center">
                    <button class="btn primary-btn" type="submit" (click)="saveContact()" [disabled]="saveContactValid()">Save <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                    <button class="btn secondary-btn" type="button" (click)="hideModalContact()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Member Modal-->
<div id="deleteMemberConfirmation" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <h6 class="modal-title">Are you sure you want to delete this member?</h6>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-success" (click)="memberDelete()">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Product Modal-->
<div id="deleteContractConfirmation" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <h6 class="modal-title">Are you sure you want to delete this contract?</h6>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-success" [disabled]="showSpinner" (click)="deleteContract()">Confirm  <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<!-- Text Modal-->
<div id="textConfirmation" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <!-- Modal content-->
        <div class="modal-content">
            <form class="form-horizontal" [formGroup]="textForm">
                <div class="modal-header">
                    <h5 class="modal-title">Send a Text Message</h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="margin-top10">
                    <div class="form-group col-sm-12">
                        <label class="control-label" for="txtMsg">Text Message <span style="color:green">(max 500 chars)</span></label>
                        <div>
                            <textarea type="text" rows="5" formControlName="txtMsg" class="form-control" maxlength="500"></textarea>
                        </div>
                    </div>
                </div>
                <div class="margin-top10">
                    <div class="form-group col-sm-12">
                        <label class="control-label" for="mediaURL">Media URL</label>
                        <div>
                            <input formControlName="mediaURL" class="form-control" maxlength="300" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="sendEmail" formControlName="sendEmail" name="sendEmail">
                        <label class="custom-control-label custom-control-label-disabled" for="sendEmail">Send Email</label>
                    </div>
                    <button type="submit" class="btn btn-success" (click)="sendText()">Send</button>
                    <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div id="unEqualPayConfirmModal" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <h6 class="modal-title">Member fee must be equal to given product amount. Are you sure you want to save the contract?</h6>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-success" [disabled]="showSpinner" (click)="saveContract(true)">Confirm  <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
